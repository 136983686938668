import React from "react";

import { ITransaction } from "@/api/interfaces/transactionLayout";
import { Col, Row } from "@/base/components/Layout";
import { MarginProps } from "@/base/components/Layout/constants";
import Transaction from "@/pages/Transactions/components/Transaction";
import { createUseStyles } from "@/theme";

const useStyles = createUseStyles(({ spacing, color }) => ({
  transactionList: {
    width: "100%",
    "& > div": {
      padding: [spacing.m, 0],
      borderBottom: `1px solid ${color.gray5}`,
    },
    "& > div:last-child": {
      borderBottom: "none",
    },
  },
}));

type Props = MarginProps & {
  transactions: ITransaction[];
};

const TransactionList: React.FC<Props> = ({ transactions, ...props }) => {
  const classes = useStyles();

  return (
    <Col
      col={12}
      className={classes.transactionList}
      directionColumn
      {...props}
    >
      {transactions.map((transaction, idx) => (
        <Row noMargin key={idx}>
          <Transaction transaction={transaction} />
        </Row>
      ))}
    </Col>
  );
};

export default TransactionList;
