import React from "react";

import { CmsBlock } from "@/base/components/Global";

const getBlock = (
  children: React.ReactNode,
  key: number,
  isLast: boolean,
  isSmall?: boolean,
): React.ReactNode => (
  <CmsBlock key={key} addBreak={!isLast} isSmall={isSmall}>
    {children}
  </CmsBlock>
);

const isLastBlock = (len: number, idx: number): boolean => len - 1 === idx;

export { getBlock, isLastBlock };
