import React from "react";
import { useTranslation } from "react-i18next";

import EditIcon from "@/assets/svg/edit.svg?react";
import TextField from "@/base/components/Form/TextField";
import Link from "@/base/components/Global/Link";
import P from "@/base/components/Global/P";
import { Row } from "@/base/components/Layout";
import Col from "@/base/components/Layout/Col";
import { NavigationPath } from "@/constants/navigation";

const PasswordBlock: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Row noMargin>
      <Col col={12} colMd={8}>
        <TextField
          value="***************"
          type="password"
          label={t("field_password_label")}
          isDisabled
          id="field_password"
        />
      </Col>
      <Col col={12}>
        <P gray3 xSmall>
          {t("keep_password_secure")}
        </P>
      </Col>
      <Col col={12}>
        <Link
          brandColor
          navigateTo={NavigationPath.ChangePassword}
          suffixIcon={<EditIcon />}
        >
          {t("action_edit")}
        </Link>
      </Col>
    </Row>
  );
};

export default PasswordBlock;
