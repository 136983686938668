import React, { useCallback, useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";
import { useScrollDirection } from "react-use-scroll-direction";

import { VisibilityBlock } from "@/base/components/Layout";
import { useMediaQuery } from "@/base/components/MediaQueryProvider";
import MobileBlackTopBar from "@/base/components/TopBar/MobileTopBar/MobileBlackTopBar";
import MobileTransparentTopBar from "@/base/components/TopBar/MobileTopBar/MobileTransparentTopBar";
import { handleScrollToTop } from "@/base/utils/scrollHelpers";
import { SessionStorageKey, setSessionStorage } from "@/base/utils/storage";
import { INavigationPath, NavigationPath } from "@/constants/navigation";
import { useAppDispatch, useAppSelector } from "@/store";
import { fetchMenu, selectMenu } from "@/store/cms";
import { fetchPerks, selectPerks } from "@/store/perks";
import { shouldLoad } from "@/store/utils";
import { createUseStyles } from "@/theme";

import DesktopTopBar from "./DesktopTopBar";
import config from "@/constants/config";

type TopBarProps = {
  isMenuOpen: boolean;
  backPage?: INavigationPath;
  showNavigationToHome?: boolean;
  openMenu: () => void;
};

export const HEADER_HEIGHT = 56;
const TOP_NAVBAR_ID = "top-navbar";

export const useSharedTopBarStyles = createUseStyles(
  ({ color, spacing, font, zIndex }) => ({
    headerContainer: {
      position: "sticky",
      height: HEADER_HEIGHT,
      zIndex: zIndex.stickyMenu,
      paddingTop: spacing.xs,
      paddingBottom: spacing.xs,
      backgroundColor: color.black,
      color: color.white,
      width: "100%",
      display: "flex",
      top: 0,
    },
    pageTitle: {
      fontWeight: font.weight.m,
      fontSize: font.size.s,
    },
    headerLink: {
      paddingLeft: spacing.l,
      paddingRight: spacing.l,
    },
    profileIcon: {
      marginRight: spacing.m,
    },
    startIcon: {
      marginLeft: spacing.m,
      marginRight: spacing.m,
    },
    hide: {
      top: -100,
      transition: "top 0.4s ease-in",
    },
    reveal: {
      top: 0,
      transition: "top .4s ease-out",
    },
    userFullName: {
      textWrap: "nowrap",
    },
    userActions: {
      gap: spacing.m,
    },
    topBlock: {
      height: HEADER_HEIGHT,
      fontSize: font.size.s,
      alignItems: "center",
    },
    userName: {
      display: "flex",
      alignItems: "center",
    },
    backIcon: {
      marginRight: spacing.l,
    },
  }),
);

const TopBar: React.FC<TopBarProps> = ({
  isMenuOpen,
  openMenu,
  backPage,
  showNavigationToHome,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // todo: search for alternative library with typescript support
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const { isScrollingUp, isScrollingDown, isScrolling } = useScrollDirection();
  const [{ isMobile, isLandscape }] = useMediaQuery();
  const { isAuthenticated } = useAuth();

  const perks = useAppSelector(selectPerks);
  const menu = useAppSelector(selectMenu);

  const [shouldHide, setShouldHide] = useState(false);
  const [shouldReveal, setShouldReveal] = useState(false);

  const handleBusinessLogoClick = useCallback(() => {
    if (location.pathname === NavigationPath.Home) {
      handleScrollToTop();
    } else {
      setSessionStorage(SessionStorageKey.PAGES_HISTORY, []);
      navigate(NavigationPath.Home);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (isMobile && !isLandscape) {
      setShouldHide(false);
    }
  }, [isLandscape]);

  useEffect(() => {
    if (isMobile && isLandscape && !isMenuOpen) {
      if (isScrollingDown && window.scrollY >= HEADER_HEIGHT) {
        setShouldHide(true);
        setShouldReveal(false);
      }
      if (isScrollingUp && !isMenuOpen) {
        setShouldHide(false);
        setShouldReveal(true);
      }
    }
  }, [isScrollingDown, isScrollingUp]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (
      !isScrolling &&
      isMobile &&
      window.scrollY >= HEADER_HEIGHT &&
      !isMenuOpen &&
      isLandscape
    ) {
      timer = setTimeout(() => {
        setShouldHide(true);
        setShouldReveal(false);
      }, 2000);
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [isScrolling, isMenuOpen]);

  useEffect(() => {
    if (isAuthenticated && shouldLoad(perks)) {
      void dispatch(fetchPerks());
    }

    if (shouldLoad(menu)) {
      void dispatch(fetchMenu());
    }
  }, [isAuthenticated, menu, perks]);

  return (
    <div id={TOP_NAVBAR_ID}>
      <VisibilityBlock aboveMd>
        <DesktopTopBar
          businessLogoAction={handleBusinessLogoClick}
          openMenu={openMenu}
          shouldHide={shouldHide}
          shouldReveal={shouldReveal}
        />
      </VisibilityBlock>
      <VisibilityBlock belowSm>
        {config.routerConfig.isGiftCardMode && (
          <MobileBlackTopBar
            businessLogoAction={handleBusinessLogoClick}
            shouldHide={shouldHide}
            shouldReveal={shouldReveal}
            openMenu={openMenu}
          />
        )}
        {!config.routerConfig.isGiftCardMode && (
          <MobileTransparentTopBar
            backPage={backPage}
            showNavigationToHome={showNavigationToHome}
          />
        )}
      </VisibilityBlock>
    </div>
  );
};

export default TopBar;
