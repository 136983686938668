import React from "react";
import { useTranslation } from "react-i18next";

import { ITransaction } from "@/api/interfaces/transactionLayout";
import { P } from "@/base/components/Global";
import { Col, Row } from "@/base/components/Layout";
import { formatDate, formatMoney } from "@/base/utils/formatting";
import { useAppSelector } from "@/store";
import { selectCurrentLanguage } from "@/store/language";
import { createUseStyles } from "@/theme";

const useStyles = createUseStyles({
  row: {
    justifyContent: "space-between",
  },
});

type Props = {
  transaction: ITransaction;
};

const Transaction: React.FC<Props> = ({ transaction }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const currentLanguage = useAppSelector(selectCurrentLanguage);

  return (
    <>
      <Row noMargin className={classes.row}>
        <Col col={9} noMargin>
          <P bold gray2 noMargin>
            {transaction.company}
          </P>
        </Col>
        <Col col={3} noMargin alignRight>
          <P bold gray2 noMargin>
            {formatMoney(transaction.totalAmount, currentLanguage, true)}
          </P>
        </Col>
      </Row>
      <Row noMargin className={classes.row}>
        <Col col={9} noMargin>
          <P gray3 xSmall noMargin>
            {formatDate(transaction.date, true, ".")}{" "}
            {`#${transaction.invoiceNumber}`}
          </P>
        </Col>
        <Col col={3} noMargin alignRight>
          <P gray3 xSmall noMargin>
            {t("transaction_amount")}
          </P>
        </Col>
      </Row>
    </>
  );
};

export default Transaction;
