import React, { ReactNode, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { PERKS_BLOCK_ID } from "@/base/blocks/PerkBlock/PerkBlock";
import { scrollToWithOffset } from "@/base/utils/scrollHelpers";
import {
  getSessionStorage,
  SessionStorageKey,
  setSessionStorage,
} from "@/base/utils/storage";
import { NavigationPath } from "@/constants/navigation";
import { useAppSelector } from "@/store/hooks";
import { hasData } from "@/store/utils";
import { createUseStyles } from "@/theme";

const useStyles = createUseStyles(({}) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
}));

type LayoutWrapperProps = {
  children: ReactNode;
};

const LayoutWrapper: React.FC<LayoutWrapperProps> = ({ children }) => {
  const classes = useStyles();
  const location = useLocation();
  const benefits = useAppSelector((state) => state.user.benefits);
  const pagesHistory = getSessionStorage(SessionStorageKey.PAGES_HISTORY, []);
  const prevLocation = pagesHistory[0];

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;

    if (
      hasData(benefits) &&
      prevLocation &&
      prevLocation.includes(NavigationPath.PerkGroupPage)
    ) {
      // we need to wait a little bit until data is loaded
      timeout = setTimeout(() => scrollToWithOffset(PERKS_BLOCK_ID), 500);
    }

    return () => clearTimeout(timeout);
  }, [benefits, location.pathname]);

  useEffect(() => {
    if (prevLocation !== location.pathname) {
      setSessionStorage(SessionStorageKey.PAGES_HISTORY, [
        location.pathname,
        ...pagesHistory,
      ]);
    }
  }, [location.pathname]);

  return <div className={classes.container}>{children}</div>;
};

export default LayoutWrapper;
