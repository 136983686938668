import { request } from "@/api";
import { FetchPerkRequest, IPerk } from "@/api/interfaces/perkLayouts";
import { ApiPath } from "@/api/paths";
import { ApiRequest, RequestResponse } from "@/api/types";

const fetchPerks: ApiRequest<RequestResponse<IPerk[]>, void> = async (
  options,
) => request({ ...options, api: ApiPath.User.perks, canFail: true });

const fetchPerk: ApiRequest<RequestResponse<IPerk>, FetchPerkRequest> = async ({
  perkId,
  perkCrmCode,
  ...options
}) =>
  request({
    ...options,
    api: `${ApiPath.User.perkDetails}?id=${perkId}&code=${perkCrmCode}`,
    canFail: true,
  });

const PerkController = {
  fetchPerk,
  fetchPerks,
};

export default PerkController;
