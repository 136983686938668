import { createSlice } from "@reduxjs/toolkit";

import { ILoyaltyProgram } from "@/api/interfaces/loyaltyProgramsLayout";
import { ITransaction } from "@/api/interfaces/transactionLayout";
import { IUserBenefit } from "@/api/interfaces/userBenefitLayout";
import { IUserData } from "@/api/interfaces/userLayouts";
import { ApiGetStateMachine, ArrayWrapper } from "@/store/types";
import {
  setStateFailed,
  setStateInitial,
  setStatePending,
} from "@/store/utils";

import {
  fetchBenefits,
  fetchLatestTransactions,
  fetchLoyaltyPrograms,
  fetchTransactions,
  fetchUserData,
} from "./actions";

type UserState = {
  userData: ApiGetStateMachine<IUserData>;
  loyaltyPrograms: ApiGetStateMachine<ArrayWrapper<ILoyaltyProgram>>;
  benefits: ApiGetStateMachine<ArrayWrapper<IUserBenefit>>;
  transactions: ApiGetStateMachine<ArrayWrapper<ITransaction>>;
  latestTransactions: ApiGetStateMachine<ArrayWrapper<ITransaction>>;
};

const initialState: UserState = {
  userData: { status: "idle" },
  loyaltyPrograms: { status: "idle" },
  benefits: {
    status: "idle",
  },
  transactions: {
    status: "idle",
  },
  latestTransactions: {
    status: "idle",
  },
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    clearUser(state) {
      setStateInitial(
        state.userData,
        (nextState) => (state.userData = nextState),
      );
    },
    userRefreshing(state) {
      setStateInitial(
        state.loyaltyPrograms,
        (nextState) => (state.loyaltyPrograms = nextState),
      );
      setStateInitial(
        state.benefits,
        (nextState) => (state.benefits = nextState),
      );
      setStateInitial(
        state.transactions,
        (nextState) => (state.transactions = nextState),
      );
      setStateInitial(
        state.latestTransactions,
        (nextState) => (state.latestTransactions = nextState),
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserData.pending, (state) => {
        setStatePending(
          state.userData,
          (nextState) => (state.userData = nextState),
        );
      })
      .addCase(fetchUserData.fulfilled, (state, { payload }) => {
        state.userData = { status: "succeeded", ...payload };
      })
      .addCase(fetchUserData.rejected, (state) => {
        setStateFailed(
          state.userData,
          (nextState) => (state.userData = nextState),
        );
      });

    builder
      .addCase(fetchLoyaltyPrograms.pending, (state) => {
        setStatePending(
          state.loyaltyPrograms,
          (nextState) => (state.loyaltyPrograms = nextState),
        );
      })
      .addCase(fetchLoyaltyPrograms.fulfilled, (state, { payload }) => {
        state.loyaltyPrograms = { status: "succeeded", array: payload };
      })
      .addCase(fetchLoyaltyPrograms.rejected, (state) => {
        setStateFailed(
          state.loyaltyPrograms,
          (nextState) => (state.loyaltyPrograms = nextState),
        );
      });

    builder
      .addCase(fetchBenefits.pending, (state) => {
        setStatePending(
          state.benefits,
          (nextState) => (state.benefits = nextState),
        );
      })
      .addCase(fetchBenefits.fulfilled, (state, { payload }) => {
        state.benefits = { status: "succeeded", array: payload };
      })
      .addCase(fetchBenefits.rejected, (state) => {
        setStateFailed(
          state.benefits,
          (nextState) => (state.benefits = nextState),
        );
      });

    builder
      .addCase(fetchTransactions.pending, (state) => {
        setStatePending(
          state.transactions,
          (nextState) => (state.transactions = nextState),
        );
      })
      .addCase(fetchTransactions.fulfilled, (state, { payload }) => {
        state.transactions = { status: "succeeded", array: payload };
      })
      .addCase(fetchTransactions.rejected, (state) => {
        setStateFailed(
          state.transactions,
          (nextState) => (state.transactions = nextState),
        );
      });

    builder
      .addCase(fetchLatestTransactions.pending, (state) => {
        setStatePending(
          state.latestTransactions,
          (nextState) => (state.latestTransactions = nextState),
        );
      })
      .addCase(fetchLatestTransactions.fulfilled, (state, { payload }) => {
        state.latestTransactions = { status: "succeeded", array: payload };
      })
      .addCase(fetchLatestTransactions.rejected, (state) => {
        setStateFailed(
          state.latestTransactions,
          (nextState) => (state.latestTransactions = nextState),
        );
      });
  },
  selectors: {
    selectUserData: (state) => state.userData,
    selectIsUserDataLoaded: (state) => state.userData.status === "succeeded",
    selectEmails: (state) =>
      state.userData.status === "succeeded" ? state.userData.emails : [],
    selectPhones: (state) =>
      state.userData.status === "succeeded" ? state.userData.phones : [],
    selectAddresses: (state) =>
      state.userData.status === "succeeded" ? state.userData.addresses : [],
    selectNewsletters: (state) =>
      state.userData.status === "succeeded" ? state.userData.newsletters : [],
    selectLoyaltyPrograms: (state) => state.loyaltyPrograms,
    selectUserTransactions: (state) => state.transactions,
    selectUserLatestTransactions: (state) => state.latestTransactions,
  },
});

export const {
  selectUserData,
  selectLoyaltyPrograms,
  selectUserTransactions,
  selectUserLatestTransactions,
  selectEmails,
  selectPhones,
  selectIsUserDataLoaded,
  selectAddresses,
  selectNewsletters,
} = userSlice.selectors;

export const { clearUser, userRefreshing } = userSlice.actions;

export default userSlice.reducer;
