import React, {
  ChangeEvent,
  ChangeEventHandler,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { selectClasses } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import ArrowSelectIcon from "@/assets/svg/arrow-select.svg?react";
import SelectedIcon from "@/assets/svg/selected-icon.png";
import { createUseStyles } from "@/theme";

export type Value = {
  value: string;
  label: string;
};

export type SelectListProps = {
  id: string;
  label: string;
  currentValue?: string | null;
  values?: Value[] | null;
  initialValue?: string | null;
  hasError?: boolean;
  helperText?: string | boolean;
  onChange?: ChangeEventHandler<HTMLSelectElement>;
  displayEmpty?: boolean;
  disabled?: boolean;
};

const useStyles = createUseStyles(({ spacing, borderRadius, color, font }) => ({
  container: {
    width: "100%",
    position: "relative",
    paddingBottom: spacing.m,

    "& > .MuiFormControl-root": {
      "& > .MuiInputLabel-root": {
        height: 24,
        lineHeight: font.lineHeight.m,
        color: color.gray4,
        fontWeight: font.weight.s,
        fontSize: font.size.s,
        top: -3,
        "&.MuiInputLabel-shrink": {
          top: 0,
          paddingRight: spacing.xs,
          backgroundColor: color.white,
          color: color.gray3,
        },
      },
      "& > .MuiOutlinedInput-root": {
        "& > .MuiSelect-select": {
          padding: [spacing.m, spacing.l],
          height: 24,
          lineHeight: font.lineHeight.m,
        },
        "&.Mui-error > .MuiOutlinedInput-notchedOutline": {
          borderColor: color.error,
        },
        borderRadius: borderRadius.s,
        "&.Mui-focused > .MuiOutlinedInput-notchedOutline": {
          borderColor: color.primaryBrand,
        },
      },
      "& > .MuiFormHelperText-root": {
        color: color.gray3,
        marginLeft: spacing.l,
        marginTop: spacing.xs,
        fontWeight: font.weight.m,
        fontSize: font.size.xxs,
        lineHeight: font.lineHeight.xs,
        fontFamily: font.family,
        position: "absolute",
        top: "100%",
        "&.Mui-error": {
          color: color.error,
        },
      },
    },
  },
  selectMenu: {
    borderRadius: borderRadius.s,
    padding: "0 !important",
    maxHeight: 400,
    "& > .MuiMenuItem-root": {
      fontWeight: font.weight.m,
      fontSize: font.size.s,
      lineHeight: font.lineHeight.m,
      minHeight: "auto !important",
      padding: spacing.l,
      "&.Mui-selected": {
        backgroundImage: `url(${SelectedIcon})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: `center right ${spacing.m}px`,
        backgroundColor: `${color.black} !important`,
        color: color.white,
        position: "relative",
        "&:hover": {
          backgroundImage: `url(${SelectedIcon})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: `center right ${spacing.m}px`,
          backgroundColor: color.black,
          color: color.white,
          position: "relative",
        },
      },
      "&:hover": {
        background: color.black,
        color: color.white,
      },
    },
  },
}));

const SelectList: React.FC<SelectListProps> = React.forwardRef<
  SelectListProps,
  SelectListProps
>(function SelectList(
  {
    id,
    label,
    values = [],
    initialValue,
    onChange,
    hasError,
    helperText,
    currentValue,
    displayEmpty,
    disabled,
    ...props
  }: SelectListProps,
  ref,
) {
  const classes = useStyles();
  const [value, setValue] = useState<string | number>(initialValue ?? "");
  const changeValue = (e: React.ChangeEvent<HTMLInputElement>) =>
    setValue(e.target.value);

  useEffect(() => {
    if (onChange) {
      onChange({
        target: {
          ...props,
          value,
        },
        type: "change",
      } as ChangeEvent<HTMLSelectElement>);
    }
  }, [value]);

  useEffect(() => {
    if (currentValue !== undefined && currentValue !== value) {
      setValue(currentValue ?? "");
    }
  }, [currentValue]);
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <FormControl fullWidth>
        <InputLabel id={id}>{label}</InputLabel>
        <Select
          MenuProps={{
            classes: {
              list: classes.selectMenu,
            },
          }}
          inputProps={{
            onChange: changeValue,
            ref,
          }}
          labelId={id}
          label={label}
          disabled={disabled}
          value={value}
          error={hasError}
          IconComponent={(props) => <ArrowSelectIcon {...props} />}
          sx={{
            [`& .${selectClasses.icon}`]: {
              right: "12px",
              top: "12px",
            },
          }}
          {...props}
        >
          {displayEmpty && (
            <MenuItem value="">{t("selectlist_unselected")}</MenuItem>
          )}
          {values?.map((v, idx) => (
            <MenuItem value={v.value} key={`${id}-${idx}`}>
              {v.label}
            </MenuItem>
          ))}
        </Select>
        {helperText && (
          <FormHelperText error={hasError}>{helperText}</FormHelperText>
        )}
      </FormControl>
    </div>
  );
});

export default SelectList;
