import React from "react";
import { useTranslation } from "react-i18next";

import { AddressType, IAddress } from "@/api/interfaces/userLayouts";
import EditIcon from "@/assets/svg/edit.svg?react";
import { H5, Link, P } from "@/base/components/Global";
import { Col, Row } from "@/base/components/Layout";
import { AddressID, NavigationPath } from "@/constants/navigation";
import AddressText from "@/pages/Address/AddressText";
import { getAddressLabels } from "@/pages/Address/helpers";
import { createUseStyles } from "@/theme";

const useStyles = createUseStyles(({ spacing }) => ({
  container: {
    paddingBottom: spacing.m,
  },
  btnToBottom: {
    marginTop: "auto",
  },
  columnOverride: {
    flexBasis: "initial",
    justifyContent: "flex-start",
    flexGrow: 0,
  },
}));

type Props = {
  address: IAddress;
  type: AddressType;
};

const RenderAddress: React.FC<Props> = ({ address, type }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { title, iconUrl } = getAddressLabels(type, address);

  return (
    <Col
      noMargin
      col={12}
      colMd={6}
      directionColumn
      className={classes.container}
    >
      <Col noVerticalMargin col={12} className={classes.columnOverride}>
        <H5 prefixIcon={iconUrl} gray1>
          {type === AddressType.MAILING && address.alias
            ? address.alias
            : // @ts-expect-error: waits for translations to accept dynamic keys
              t(title)}
        </H5>
      </Col>
      <Row>
        {type !== AddressType.MAILING && (
          <Col noMarginTop col={12} className={classes.columnOverride}>
            <P noMarginTop small gray3>
              {address.alias}
            </P>
          </Col>
        )}
        <AddressText {...address} />
        <Col col={12} className={classes.columnOverride}>
          <Link
            brandColor
            navigateTo={NavigationPath.EditAddress.replace(
              AddressID,
              String(address?.id),
            )}
            suffixIcon={<EditIcon />}
            className={classes.btnToBottom}
          >
            {t("action_edit_address")}
          </Link>
        </Col>
      </Row>
    </Col>
  );
};

export default RenderAddress;
