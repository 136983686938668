import React, { useEffect, useRef, useState } from "react";
import { motion, SpringOptions, useSpring } from "framer-motion";

import { useMediaQuery } from "../MediaQueryProvider";

const spring: SpringOptions = {
  stiffness: 300,
  damping: 40,
};

const transition = {
  ...spring,
  type: "spring",
};

export type CardSideProps = {
  maxWidth?: string;
  maxHeight?: string;
  width: string;
  height: string;
  minWidth?: string;
  minHeight?: string;
  isVisible: boolean;
};

type FlippableCardProps = Omit<CardSideProps, "isVisible"> & {
  frontside: (props: CardSideProps) => React.ReactNode;
  backside: (props: CardSideProps) => React.ReactNode;
  disableHoverEffect?: boolean;
  isFlipped?: boolean;
};

export const FlippableCard: React.FC<FlippableCardProps> = ({
  frontside,
  backside,
  disableHoverEffect = false,
  isFlipped = false,
  ...props
}) => {
  const [{ isMobile }] = useMediaQuery();

  const [rotateXaxis, setRotateXaxis] = useState(0);
  const [rotateYaxis, setRotateYaxis] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  const handleMouseMove: React.MouseEventHandler<HTMLDivElement> = (event) => {
    const element = ref.current;

    if (!element) return;
    if (isMobile) return;

    const elementRect = element.getBoundingClientRect();
    const elementWidth = elementRect.width;
    const elementHeight = elementRect.height;
    const elementCenterX = elementWidth / 2;
    const elementCenterY = elementHeight / 2;
    const mouseX = event.clientY - elementRect.y - elementCenterY;
    const mouseY = event.clientX - elementRect.x - elementCenterX;
    const degreeX = (mouseX / elementWidth) * 20; //The number is the rotation factor
    const degreeY = (mouseY / elementHeight) * 20; //The number is the rotation factor
    setRotateXaxis(degreeX);
    setRotateYaxis(degreeY);
  };

  const handleMouseEnd = () => {
    setRotateXaxis(0);
    setRotateYaxis(0);
  };

  const dx = useSpring(0, spring);
  const dy = useSpring(0, spring);

  useEffect(() => {
    dx.set(-rotateXaxis);
    dy.set(rotateYaxis);
  }, [rotateXaxis, rotateYaxis]);

  return (
    <motion.div
      transition={transition}
      style={{
        perspective: "1200px",
        transformStyle: "preserve-3d",

        maxWidth: `${props.maxWidth}`,
        maxHeight: `${props.maxHeight}`,

        width: `${props.width}`,
        height: `${props.height}`,

        minWidth: `${props.minWidth}`,
        minHeight: `${props.minHeight}`,

        scrollSnapAlign: "center",
        display: "flex",
      }}
    >
      <motion.div
        ref={ref}
        whileHover={disableHoverEffect ? { scale: 1 } : { scale: 1.1 }} //Change the scale of zooming in when hovering
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseEnd}
        transition={transition}
        style={{
          width: "100%",
          height: "100%",
          rotateX: dx,
          rotateY: dy,
        }}
      >
        <div
          style={{
            perspective: "1200px",
            transformStyle: "preserve-3d",
            width: "100%",
            height: "100%",
          }}
        >
          <motion.div
            animate={{ rotateY: isFlipped ? -180 : 0 }}
            transition={transition}
            style={{
              width: "100%",
              height: "100%",
              zIndex: isFlipped ? 0 : 1,
              backfaceVisibility: "hidden",
              position: "absolute",
            }}
          >
            {frontside({ ...props, isVisible: !isFlipped })}
          </motion.div>
          <motion.div
            initial={{ rotateY: 180 }}
            animate={{ rotateY: isFlipped ? 0 : 180 }}
            transition={transition}
            style={{
              width: "100%",
              height: "100%",
              zIndex: isFlipped ? 1 : 0,
              backfaceVisibility: "hidden",
              position: "absolute",
            }}
          >
            {backside({ ...props, isVisible: isFlipped })}
          </motion.div>
        </div>
      </motion.div>
    </motion.div>
  );
};
