import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import EditFormPage from "@/base/components/Form/EditFormPage";
import GroupedFields from "@/base/components/Form/EditFormPage/GroupedFields";
import ModalArray from "@/base/components/Modal/ModalArray";
import { PHONE_ICON } from "@/constants/blobIcons";
import config from "@/constants/config";
import { GOOGLE_ANALYTICS_ATTRIBUTES } from "@/constants/googleAnalytics";
import { NavigationPath } from "@/constants/navigation";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import {
  fetchUserData,
  selectIsUserDataLoaded,
  selectPhones,
} from "@/store/user";

import EditPhone from "./components/EditPhone";

const PhonesPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const phones = useAppSelector(selectPhones);
  const isUserDataLoaded = useAppSelector(selectIsUserDataLoaded);
  const [currentModal, setCurrentModal] = useState<string | null>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isUserDataLoaded && phones.length === 0) {
      navigate(NavigationPath.EditProfile);
    }
  }, [isUserDataLoaded, phones]);

  const primaryPhone = phones.find((phone) => phone.isPrimary);
  const secondaryPhones = phones.filter((phone) => !phone.isPrimary);

  return (
    <EditFormPage
      isLoading={!isUserDataLoaded || phones.length === 0}
      form={{
        headingIconUrl: PHONE_ICON,
        headingTitle: t("edit_phone_header"),
        headingSubTitle: t("edit_phone_subtitle"),
        content: t("edit_phone_helper_text"),
        addRow: t("add_phone"),
        addRowAction: () => navigate(NavigationPath.AddPhone),
        hasMaxRowsReached: phones.length >= config.appConfig.maxPhoneCount,
        maxRowsReachedText: t("max_phones_reached"),
        goBackText: t("go_to_profile"),
        goBackAction: () => navigate(NavigationPath.Profile),
      }}
    >
      <ModalArray
        modals={{
          primarySuccessModal: {
            title: t("modal_primary_phone_update_title"),
            subTitle: t("modal_primary_phone_update_subtitle"),
            content: t("modal_primary_phone_update_content"),
            analyticsAttributes:
              GOOGLE_ANALYTICS_ATTRIBUTES.PHONE_PRIMARY_SUCCESS,
          },
          editSuccessModal: {
            title: t("modal_phone_edited_title"),
            subTitle: t("modal_phone_edited_subtitle"),
            content: t("modal_phone_edited_content"),
            analyticsAttributes:
              GOOGLE_ANALYTICS_ATTRIBUTES.PHONE_CHANGE_SUCCESS,
          },
          errorModal: {
            title: t("modal_phone_error_title"),
            subTitle: t("modal_phone_error_subtitle"),
            content: t("modal_phone_error_content"),
            analyticsAttributes:
              GOOGLE_ANALYTICS_ATTRIBUTES.PHONE_CHANGE_DECLINE,
          },
          removeSuccessModal: {
            title: t("modal_phone_removed_title"),
            subTitle: t("modal_phone_removed_subtitle"),
            content: t("modal_phone_removed_content"),
            analyticsAttributes:
              GOOGLE_ANALYTICS_ATTRIBUTES.DELETE_PHONE_SUCCESS,
          },
          removeErrorModal: {
            title: t("modal_phone_remove_error_title"),
            subTitle: t("modal_phone_remove_error_subtitle"),
            content: t("modal_phone_remove_error_content"),
            analyticsAttributes:
              GOOGLE_ANALYTICS_ATTRIBUTES.DELETE_PHONE_DECLINE,
          },
        }}
        currentModal={currentModal}
        onClose={async () => {
          setCurrentModal(null);
          await dispatch(fetchUserData());
        }}
      />
      {primaryPhone && (
        <GroupedFields title={t("edit_phone_primary_address")}>
          <EditPhone setModal={setCurrentModal} {...primaryPhone} />
        </GroupedFields>
      )}

      {secondaryPhones.length > 0 && (
        <GroupedFields title={t("edit_phone_secondary_address")}>
          {secondaryPhones.map((phone) => (
            <EditPhone setModal={setCurrentModal} key={phone.id} {...phone} />
          ))}
        </GroupedFields>
      )}
    </EditFormPage>
  );
};

export default PhonesPage;
