import React, { useEffect, useRef } from "react";
import { createUseStyles } from "react-jss";
import { useInView } from "framer-motion";

import useDebounce from "@/base/hooks/useDebounce";

import { CarouselChildrenProps } from "./types";

const useStyles = createUseStyles(() => ({
  item: {
    "&:first-child": {
      marginLeft: "15rem",
    },
    // Hack to make the margin-right work on all browsers (doesn't work on Safari properly)
    "&:last-child": {
      position: "relative",

      "&::after": {
        position: "absolute",
        content: "''",
        right: "-15rem",
        width: "15rem",
        height: "100%",
      },
    },

    display: "flex",
    scrollSnapAlign: "center",

    userSelect: "none",

    flexDirection: "column",
    alignItems: "center",
  },
}));

type CarouselItemProps<T> = {
  children?: (props: CarouselChildrenProps<T>) => React.ReactNode;
  index: number;
  item: T | null;
  defaultSelectedIndex: number;
  itemContainerRef: React.RefObject<HTMLDivElement>;
};

export const CarouselItem = <T,>({
  children,
  index,
  item,
  defaultSelectedIndex,
  itemContainerRef,
}: CarouselItemProps<T>): JSX.Element => {
  const classes = useStyles();

  const selectedItemRef = useRef<HTMLDivElement>(null);

  const isInView = useInView(selectedItemRef, {
    root: itemContainerRef,
    amount: 0.9,
  });

  const isInViewDebounced = useDebounce(isInView, 100);

  useEffect(() => {
    if (defaultSelectedIndex === index) {
      selectedItemRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }
  }, []);

  const scrollIntoView = () => {
    if (!selectedItemRef.current || isInViewDebounced) return;

    selectedItemRef.current.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "center",
    });
  };

  return (
    <div
      className={classes.item}
      ref={selectedItemRef}
      onClick={() => scrollIntoView()}
    >
      {item &&
        children?.({
          index,
          item,
          isSelected: isInViewDebounced,
        })}
    </div>
  );
};
