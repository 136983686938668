import React, { RefObject, useEffect, useRef, useState } from "react";
import classNames from "classnames";

import { IPerk } from "@/api/interfaces/perkLayouts";
import AccordionPerk from "@/base/blocks/PerkBlock/AccordionPerk";
import { groupPerks } from "@/store/perks/utils";
import { createUseStyles } from "@/theme";

const useStyles = createUseStyles<{
  isCardsOpen: boolean;
}>(({ spacing, sizes }) => ({
  container: {
    justifyContent: "center",
    paddingBottom: spacing.s,
    cursor: "pointer",
    alignSelf: "flex-start",
    marginLeft: spacing.l,
    marginRight: spacing.l,

    [sizes.md]: {
      maxWidth: 343,
    },
  },
  isOpen: {},
  group: {
    transform: "translate3d(0,0,0)",
    transition: "transform 0.1s ease-in-out",
    "$container$isOpen &:not(:last-child):hover": {
      transform: "translate3d(0,-20px,0)",
    },
  },
}));

type Props = {
  perks?: IPerk[];
};

const useOnScrollToComponent = (
  ref: RefObject<HTMLElement>,
  callback: () => void,
) => {
  const determineScrollPosition = () => {
    if (!ref.current) return;

    if (ref.current.offsetTop - window.scrollY < window.innerHeight / 2) {
      callback();
      window.removeEventListener("scroll", determineScrollPosition);
    }
  };

  useEffect(() => {
    determineScrollPosition();

    window.addEventListener("scroll", determineScrollPosition);

    return () => {
      window.removeEventListener("scroll", determineScrollPosition);
    };
  }, []);
};

const PerksAccordion: React.FC<Props> = ({ perks }) => {
  const [isCardsOpen, setIsCardsOpen] = useState(false);
  const classes = useStyles({ isCardsOpen });
  const containerRef = useRef<HTMLDivElement>(null);

  const openCards = () => {
    setIsCardsOpen(!isCardsOpen);
  };

  useOnScrollToComponent(containerRef, () => {
    if (containerRef) {
      setIsCardsOpen(true);
    }
  });

  if (!perks || perks.length === 0) return null;

  return (
    <div
      ref={containerRef}
      onClick={openCards}
      className={classNames(classes.container, isCardsOpen && classes.isOpen)}
      id="giftcard-selection"
    >
      {groupPerks(perks).map((perk, idx, arr) => (
        <div className={classes.group} key={perk.name}>
          <AccordionPerk
            perk={perk}
            isOpen={isCardsOpen}
            isLastGroup={idx === arr.length - 1}
            key={perk.usageBarcode}
          />
        </div>
      ))}
    </div>
  );
};

export default PerksAccordion;
