import { TenantConfig } from "@apl-digital/tenant-config";
import { z } from "zod";

import rawConfig from "../../config.yaml";

const AppConfigSchema = z
  .object({
    maxEmailCount: z.number().positive().int().default(4),
    maxPhoneCount: z.number().positive().int().default(4),
    maxAddressCount: z.number().positive().int().default(4),
    regionsTimeout: z.number().positive().int(),
    noIndex: z.boolean().default(false),
    manifest: z.string().default("manifest.json"),
    errorPageMaxRetryCount: z.number().positive().int().nullable().default(3),
  })
  .strict();

const RouterConfigSchema = z.object({
  loyaltyHubUrl: z.string().url(),
  loyaltyProfileUrl: z.string().url(),

  regionPrefix: z.string().nullable(),

  isHubMode: z.boolean().default(false),
  isGiftCardMode: z.boolean().default(false),
  isProfileMode: z.boolean().default(false),
  isRegionalMode: z.boolean().default(false),
});

const SSOConfigSchema = z
  .object({
    authorityUrl: z.string().url(),
    defaultClientId: z.string(),
    region: z.record(z.string(), z.object({ clientId: z.string() }).strict()),
  })
  .strict();

const AssetConfigSchema = z
  .object({
    translations: z
      .object({
        url: z.string().url(),
        overrideI18nLanguage: z.string().nullable(),
      })
      .strict(),
    images: z
      .object({
        url: z.string().url(),
      })
      .strict(),
  })
  .strict();

const ApiConfigSchema = z
  .object({
    loyaltyProxy: z
      .object({
        url: z.string().url(),
      })
      .strict(),
    azure: z
      .object({
        applicationInsight: z
          .object({
            instrumentationKey: z.string(),
          })
          .strict(),
      })
      .strict(),
    google: z
      .object({
        maps: z
          .object({
            apiKey: z.string(),
          })
          .strict(),
        tagManager: z
          .object({
            code: z.string(),
          })
          .strict()
          .nullable(),
      })
      .strict(),
  })
  .strict();

const FeatureFlagsSchema = z
  .object({
    giftCardLive: z.boolean().default(true),
    finRegionUnlocked: z.boolean().default(true),
    latRegionUnlocked: z.boolean().default(true),
    litRegionUnlocked: z.boolean().default(true),
    shouldFallbackLvLanguage: z.boolean().default(true),
    enableTransactions: z.array(z.string()).default(["ee", "lv", "lt", "fi"]),
  })
  .strict();

const DevFlagsSchema = z
  .object({
    translationsDebug: z.boolean().default(false),
    storageDebug: z.boolean().default(false),
  })
  .strict();

export const SiteConfigSchema = z
  .object({
    appConfig: AppConfigSchema,
    routerConfig: RouterConfigSchema,
    ssoConfig: SSOConfigSchema,
    assetConfig: AssetConfigSchema,
    apiConfig: ApiConfigSchema,
    featureFlags: FeatureFlagsSchema,
    devFlags: DevFlagsSchema,
  })
  .strict();

export const tenantConfig = new TenantConfig(SiteConfigSchema, rawConfig);

let tenantKey = window.location.hostname.split(".").reverse();

if (
  import.meta.env.VITE_DEBUG_MODE === "true" &&
  sessionStorage.getItem("override_tenantKey")
) {
  const storageTenantKey = sessionStorage.getItem("override_tenantKey");

  if (storageTenantKey) {
    tenantKey = storageTenantKey.split(".");
  }
}

tenantConfig.setTenantKey(tenantKey);

if (process.env.NODE_ENV === "development") {
  tenantConfig.printValidationResults();
}

const config = tenantConfig.getConfig();
export default config;
