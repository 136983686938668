import React, { useEffect } from "react";

import { P } from "@/base/components/Global";
import Icon from "@/base/components/Global/Icon";
import LoaderTiny from "@/base/components/Global/LoaderTiny";
import { Col, Row } from "@/base/components/Layout";
import {
  BUSINESS_MEMBER_ICON,
  CINEMA_MEMBER_ICON,
  CLUB_MEMBER_ICON,
} from "@/constants/blobIcons";
import config from "@/constants/config";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { selectCurrentLanguage } from "@/store/language";
import { fetchLoyaltyPrograms, selectLoyaltyPrograms } from "@/store/user";
import { hasData, shouldLoad } from "@/store/utils";
import { createUseStyles } from "@/theme";

const useStyles = createUseStyles(({ spacing }) => ({
  membershipIcon: {
    marginRight: spacing.xs + spacing.xxs,
  },
  loyaltyPrograms: {
    gap: spacing.s,
  },
}));

const getProgramIcon = (levelID: number): string => {
  switch (levelID) {
    case 1009:
    case 1048:
      return CINEMA_MEMBER_ICON;
    case 1037:
    case 1047:
      return CLUB_MEMBER_ICON;
    default:
      return BUSINESS_MEMBER_ICON;
  }
};

const LoyaltyPrograms = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const loyaltyPrograms = useAppSelector(selectLoyaltyPrograms);
  const currentLanguage = useAppSelector(selectCurrentLanguage);

  const shouldFallbackLvLanguage =
    currentLanguage === "lv" && config.featureFlags.shouldFallbackLvLanguage;

  useEffect(() => {
    if (shouldLoad(loyaltyPrograms)) {
      void dispatch(
        fetchLoyaltyPrograms({
          language: shouldFallbackLvLanguage ? "en" : undefined,
        }),
      );
    }
  }, [loyaltyPrograms]);

  if (loyaltyPrograms.status === "failed") return null;

  if (!hasData(loyaltyPrograms)) return <LoaderTiny />;

  return (
    <Col noMargin directionColumn className={classes.loyaltyPrograms}>
      {loyaltyPrograms.array.map((program) => (
        <Row noMargin key={program.id}>
          <Icon
            className={classes.membershipIcon}
            icon={getProgramIcon(program.id)}
            width={17}
            height={17}
          />
          <P gray2 small noMargin>
            {program.levelName}
          </P>
        </Row>
      ))}
    </Col>
  );
};

export default LoyaltyPrograms;
