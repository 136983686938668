import React from "react";

import { IAction } from "@/api/interfaces/common";
import { Button } from "@/base/components/Global";
import { Col, Row } from "@/base/components/Layout";

type Props = {
  actions?: IAction[];
};

const GiftCardActions: React.FC<Props> = ({ actions }) => {
  if (!actions || actions.length === 0) return null;

  return (
    <Row noMargin>
      {actions.map((action, idx) => {
        const isLeft = idx % 2 === 0;

        return (
          <Col
            col={6}
            key={action.title}
            noMarginLeft={isLeft}
            noMarginRight={!isLeft}
          >
            <Button href={action.data} transparentBrand={!isLeft}>
              {action.title}
            </Button>
          </Col>
        );
      })}
    </Row>
  );
};

export default GiftCardActions;
