import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { RepeatFieldFormProps } from "@/base/components/Form/EditFormPage/types";
import { Button } from "@/base/components/Global";
import { Col } from "@/base/components/Layout";

const RepeatFieldForm: React.FC<RepeatFieldFormProps> = ({
  onSubmit,
  fieldKey,
  repeatFieldKey,
  validationSchema,
  fieldComponent,
  fieldsText,
  isSubmitting = false,
  analyticsAttributes,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const hasError = (inputName: string) =>
    Object.keys(errors).includes(inputName);

  const { ref: fieldKeyRef, ...fieldKeyProps } = register(fieldKey);
  const { ref: repeatFieldKeyRef, ...repeatFieldKeyProps } =
    register(repeatFieldKey);

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ display: "contents" }}>
      <Col col={12}>
        {fieldComponent({
          label: fieldsText.fieldLabel,
          id: fieldKey,
          placeholder: fieldsText.fieldPlaceholder,
          helperText: hasError(fieldKey) && fieldsText.fieldError,
          hasError: hasError(fieldKey),
          innerRef: fieldKeyRef,
          ...fieldKeyProps,
        })}
      </Col>
      {repeatFieldKey && (
        <Col col={12}>
          {fieldComponent({
            label: fieldsText.fieldRepeatLabel,
            id: repeatFieldKey,
            submitOnEnter: true,
            placeholder: fieldsText.fieldRepeatPlaceholder,
            helperText: hasError(repeatFieldKey) && fieldsText.fieldRepeatError,
            hasError: hasError(repeatFieldKey),
            innerRef: repeatFieldKeyRef,
            ...repeatFieldKeyProps,
          })}
        </Col>
      )}
      <Col col={12} colMd={6}>
        <Button
          type="submit"
          data-testid="repeat-field-submit-button"
          isDisabled={!isValid}
          isLoading={isSubmitting}
          analyticsAttributes={analyticsAttributes}
        >
          {fieldsText.addRowText}
        </Button>
      </Col>
    </form>
  );
};

export default RepeatFieldForm;
