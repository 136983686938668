import React from "react";

import Icon from "@/base/components/Global/Icon";
import useMediaQueryHook from "@/base/hooks/useMediaQueryHook";
import { createUseStyles, getTheme } from "@/theme";

const HEIGHT = 48;
const ICON_MIN_WIDTH = 24;
const ANIMATION_LENGTH = "1.5s";

type StyleProps = {
  current: number;
  progressCompleted: number;
  background?: string;
};

const useStyles = createUseStyles<StyleProps>(
  ({ spacing, font, borderRadius, color, sizes }) => ({
    container: {
      width: "100%",
      height: HEIGHT,
      background: color.gray6,
      borderRadius: borderRadius.s,
      display: "flex",
      alignItems: "center",
      gap: spacing.s,
      fontWeight: 700,
      fontSize: font.size.s,
      [sizes.lg]: {
        width: "100%",
      },
      [sizes.md]: {
        width: "100%",
      },
    },
    logo: {
      marginLeft: 0,
      backgroundColor: ({ background }) => background,
      gap: spacing.l,
      height: HEIGHT,
      display: "flex",
      color: color.white,
      justifyContent: "center",
      borderRadius: borderRadius.s,
      padding: [0, spacing.l],
    },
    icon: {
      position: "relative",
      minWidth: ICON_MIN_WIDTH,
      justifyContent: "right",
      marginLeft: "auto",
      marginRight: spacing.s,
      height: HEIGHT,
      display: "flex",
      padding: [0, spacing.s],
    },
    vouchersCount: {
      height: 14,
      width: 14,
      position: "absolute",
      top: spacing.s,
      right: spacing.xxs,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontWeight: "normal",
      fontSize: font.size.x3s,
      backgroundColor: color.primaryBrand,
      color: color.white,
      borderRadius: "50%",
    },
    "@keyframes expand": {
      from: {
        width: "0%",
      },
      to: {
        width: ({ progressCompleted }) => `${progressCompleted}%`,
      },
    },
    "@keyframes fadeIn": {
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
    },
    completed: {
      animation: `$expand ${ANIMATION_LENGTH} ease`,
      backgroundColor: ({ background }) => background,
      height: HEIGHT,
      display: "flex",
      alignItems: "center",
      justifyContent: "right",
      marginLeft: -20,
      width: ({ progressCompleted }) => `${progressCompleted}%`,
      minWidth: "fit-content",
      padding: [0, spacing.s],
      color: color.white,
      borderRadius: borderRadius.s,
      "& span": {
        animation: `$fadeIn ${ANIMATION_LENGTH} ease-in`,
        opacity: 1,
      },
    },
    total: {
      display: "flex",
      color: color.gray3,
      alignItems: "center",
    },
    totalNumber: {
      paddingLeft: spacing.s,
      whiteSpace: "nowrap",
      animation: `$fadeIn ${ANIMATION_LENGTH} ease`,
      opacity: 1,
    },
    progress: {
      display: "flex",
      flex: "1",
    },
  }),
);

type Props = {
  brandLogoURL?: string;
  brandIconURL?: string;
  brandColor?: string;
  completed: number;
  total: number;
  isMoneyBased: boolean;
  message?: string;
  vouchersCount: number;
};

const calculateRemaining = (
  total: number,
  current: number,
  isMoney: boolean,
): string => {
  let result: number = total;
  if (total > current) result = total - current;

  return result.toFixed(isMoney ? 2 : 0);
};

const calculateCompleted = (
  total: number,
  completed: number,
  isMoney: boolean,
): string => {
  let result = completed;
  if (completed > total) result = total;
  return result.toFixed(isMoney ? 2 : 0);
};

const BrandProgressBar: React.FC<Props> = ({
  brandLogoURL,
  brandIconURL,
  brandColor,
  completed,
  total,
  message,
  isMoneyBased,
  vouchersCount,
}) => {
  let progressCompleted = (completed * 100) / total;
  if (progressCompleted > 100) progressCompleted = 100;
  const theme = getTheme();

  const classes = useStyles({
    current: completed,
    progressCompleted: progressCompleted,
    background: brandColor,
  });

  const isCurrentSizeSm = useMediaQueryHook(theme.sizes.smMinWidth);

  return (
    <div className={classes.container}>
      <div className={classes.logo}>
        <Icon width={60} height="auto" icon={brandLogoURL} fill="#FFFFFF" />
      </div>
      <div className={classes.progress}>
        {completed !== 0 && (
          <div className={classes.completed}>
            <span
              style={{
                visibility:
                  isMoneyBased && !isCurrentSizeSm && progressCompleted < 25
                    ? "hidden"
                    : "visible",
              }}
            >
              {calculateCompleted(total, completed, isMoneyBased)}
              {isMoneyBased && " €"}
            </span>
          </div>
        )}
        {progressCompleted !== 100 && (
          <div className={classes.total}>
            {completed !== 0 && (
              <span className={classes.totalNumber}>
                {calculateRemaining(total, completed, isMoneyBased)}
                {isMoneyBased && " €"}
              </span>
            )}
            {completed === 0 && <span>{message}</span>}
          </div>
        )}
      </div>
      <div className={classes.icon}>
        <Icon icon={brandIconURL} />
        {vouchersCount > 0 && (
          <div className={classes.vouchersCount}>{vouchersCount}</div>
        )}
      </div>
    </div>
  );
};

export default BrandProgressBar;
