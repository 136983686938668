import { useEffect } from "react";
import smoothscroll from "smoothscroll-polyfill";

import "ts-replace-all";

const Polyfills = () => {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    smoothscroll.polyfill();
  });
  return null;
};

export default Polyfills;
