import React, { useMemo } from "react";
import { useAuth } from "react-oidc-context";

import { IRegionLocale } from "@/api/interfaces/countriesLayout";
import IMenuItemLayout from "@/api/interfaces/menuLayout";
import CheckedIcon from "@/assets/svg/checkedIcon.svg?react";
import EmptyIcon from "@/assets/svg/emptyCheckbox_sm.svg?react";
import AccordionItem from "@/base/components/AccordionList/AccordionItem";
import MenuItem from "@/base/components/BurgerMenu/components/MenuItem";
import { useUser } from "@/base/components/UserProvider";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { changeSelectedLanguage } from "@/store/language";
import { selectCurrentLanguage } from "@/store/language/selectors";
import { selectAllLocales, selectCurrentLocales } from "@/store/region";
import { selectUserData } from "@/store/user";

type LanguagesMenuProps = {
  item: IMenuItemLayout;
  closeMenu?: () => void;
};

const LanguagesMenu: React.FC<LanguagesMenuProps> = ({ item, closeMenu }) => {
  const userData = useAppSelector(selectUserData);
  const [{ updateProfile }] = useUser();
  const { isAuthenticated } = useAuth();
  const currentLocales = useAppSelector(selectCurrentLocales);
  const allLocales = useAppSelector(selectAllLocales);
  const currentLanguage = useAppSelector(selectCurrentLanguage);
  const dispatch = useAppDispatch();

  const locales = useMemo(() => {
    let localesList: IRegionLocale[] = [];

    if (currentLocales) {
      localesList = currentLocales;
    } else if (allLocales) {
      localesList = allLocales;
    }

    // Add the current language to the list if it's not already there, otherwise it would appear that there is no language selected in the menu
    const selectedLocale = allLocales?.find(
      (locale) => locale.code === currentLanguage,
    );

    if (
      selectedLocale &&
      !localesList?.some((locale) => locale.id === selectedLocale?.id)
    ) {
      return [...localesList, selectedLocale];
    }

    return localesList;
  }, [currentLanguage, currentLocales, allLocales]);

  const setNewActiveLanguage = async (currentLocale: IRegionLocale) => {
    dispatch(changeSelectedLanguage(currentLocale.code));

    if (isAuthenticated && userData.status === "succeeded") {
      await updateProfile({
        localeId: currentLocale.id,
      });
    }
  };

  if (!locales) return null;

  return (
    <AccordionItem id={item.title} title={item.title} iconURL={item.iconURL}>
      {locales.map((locale) => (
        <MenuItem
          key={locale.id}
          onClick={async () => setNewActiveLanguage(locale)}
          closeMenu={closeMenu}
          icon={
            currentLanguage === locale.code ? <CheckedIcon /> : <EmptyIcon />
          }
        >
          {locale.name}
        </MenuItem>
      ))}
    </AccordionItem>
  );
};

export default LanguagesMenu;
