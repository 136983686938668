import { IPerk } from "@/api/interfaces/perkLayouts";

export const groupPerks = (perks: IPerk[]): IPerk[] => {
  const result: IPerk[] = [];

  for (const perk of perks) {
    const fIndex: number = result.findIndex((v) => v.name === perk.name);
    if (fIndex >= 0) {
      result[fIndex] = {
        ...result[fIndex],
        ...perk,
        balance: result[fIndex].balance + perk.balance,
        count: result[fIndex].count + 1,
      };
    } else {
      result.push({
        ...perk,
        count: 1,
      });
    }
  }

  return result;
};
