import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import LocationIcon from "@/assets/svg/apollo_location.svg?react";
import CloseIcon from "@/assets/svg/close-btn.svg?react";
import { Button, H5, Icon, P } from "@/base/components/Global";
import { Col, Row } from "@/base/components/Layout";
import GenericModal, {
  GenericModalProps,
} from "@/base/components/Modal/GenericModal";
import { APOLLO_STAR_ICON } from "@/constants/blobIcons";
import { NavigationPath } from "@/constants/navigation";
import { createUseStyles } from "@/theme";

const MAX_WIDTH = 295;

const useStyles = createUseStyles(({ spacing, borderRadius }) => ({
  banner: {
    borderRadius: borderRadius.m,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: [spacing.xl, 0],
    width: MAX_WIDTH,
    height: 193,
  },
  modalContent: {
    width: MAX_WIDTH,
  },
}));

type BenefitModalProps = GenericModalProps & {
  title?: string;
  description?: string;
  iconURL?: string;
  logoURL?: string;
  color?: string;
  slogan?: string;
  businessSystemKey?: string;
};

const BenefitModal: React.FC<BenefitModalProps> = ({
  isOpen,
  onClose,
  title,
  description,
  iconURL,
  logoURL,
  color,
  slogan,
  businessSystemKey,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <GenericModal isOpen={isOpen} onClose={onClose}>
      <Col
        noMargin
        directionColumn
        alignCenter
        className={classes.modalContent}
      >
        <Row noMargin>
          <Col hide={!title} col={10} noMarginTop>
            <H5 prefixIcon={APOLLO_STAR_ICON}>{title}</H5>
          </Col>
          <Col alignRight col={2} directionColumn>
            <CloseIcon onClick={onClose} />
          </Col>
        </Row>
        <Col col={12} alignCenter>
          <div className={classes.banner} style={{ backgroundColor: color }}>
            <Icon icon={iconURL} stroke="white" height={58} width={42} />
            <P white bold>
              {slogan}
            </P>
            <Icon icon={logoURL} fill="white" height={58} width={104} />
          </div>
        </Col>
        <Col col={12}>
          <P noMargin>{description}</P>
        </Col>
        <Row noMargin>
          <Col alignRight noMarginBottom noMarginTop>
            <Col col={6} noHorizontalMargin>
              <Button
                prefixIcon={<LocationIcon />}
                transparentBrand
                action={() => {
                  navigate(
                    `${NavigationPath.Locations}?brand=${businessSystemKey}`,
                  );
                }}
              >
                {t("modal_location_button")}
              </Button>
            </Col>
          </Col>
        </Row>
      </Col>
    </GenericModal>
  );
};

export default BenefitModal;
