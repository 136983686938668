import React from "react";

import { Col } from "@/base/components/Layout";
import { ColProps } from "@/base/components/Layout/Col";

type FormSizeProps = {
  size?: string;
  children: React.ReactNode;
} & ColProps;

const DEFAULT_COL_SIZE = 12;

const FormSize: React.FC<FormSizeProps> = ({ size, children, ...props }) => {
  let colSize: number = DEFAULT_COL_SIZE;
  switch (size) {
    case "s":
      colSize = 3;
      break;
    case "m":
      colSize = 6;
      break;
    case "l":
      colSize = 9;
      break;
    case "xl":
      colSize = 12;
      break;
    default:
      break;
  }

  return (
    <Col col={colSize} {...props}>
      {children}
    </Col>
  );
};

export default FormSize;
