import React from "react";

import { Col, Row } from "@/base/components/Layout";
import GenericModal from "@/base/components/Modal";
import { GenericModalProps } from "@/base/components/Modal/GenericModal";
import MyCard from "@/pages/HomePage/components/MyApolloClubCard/MyCard";

const ApolloClubCardModal: React.FC<GenericModalProps> = ({
  isOpen,
  onClose,
  onNavigate,
}) => (
  <GenericModal isTransparent isOpen={isOpen} closeModal={onClose}>
    <Row>
      <Col col={12} alignRight>
        <MyCard onNavigate={onNavigate} />
      </Col>
    </Row>
  </GenericModal>
);

export default ApolloClubCardModal;
