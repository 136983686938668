import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { NavigationPath } from "@/constants/navigation";
import config from "@/constants/config";

const EmptyPage: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (config.routerConfig.isGiftCardMode) {
      navigate(NavigationPath.GiftCardStatus, { replace: true });
    } else {
      navigate(NavigationPath.Home, { replace: true });
    }
  });

  return null;
};

export default EmptyPage;
