import { IBusinessNewsletter } from "@/api/interfaces/newsLetterLayout";

const findBusinessNewsletter = (
  externalIdentifier: string,
  businessNewsletters: IBusinessNewsletter[],
): IBusinessNewsletter | undefined => {
  return businessNewsletters.find(
    (newsletter) => newsletter.externalIdentifier === externalIdentifier,
  );
};

export { findBusinessNewsletter };
