import React from "react";

import { Col } from "@/base/components/Layout";
import { createUseStyles } from "@/theme";

type LocationInBuildingProps = {
  floorImageData?: string | null;
};

const useStyles = createUseStyles<LocationInBuildingProps>({
  mapImage: {
    width: 311,
    height: 438,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    display: "flex",
    backgroundImage: ({ floorImageData }) =>
      floorImageData ? `url(${floorImageData})` : undefined,
  },
});

const LocationInBuildingImage: React.FC<LocationInBuildingProps> = ({
  floorImageData,
}) => {
  const classes = useStyles({ floorImageData });

  if (!floorImageData) return null;

  return (
    <Col noMarginTop alignCenter>
      <div className={classes.mapImage} />
    </Col>
  );
};

export default LocationInBuildingImage;
