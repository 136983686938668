import { request } from "@/api";
import { INewsletter } from "@/api/interfaces/newsLetterLayout";
import { ApiPath } from "@/api/paths";
import { ApiRequest, RequestResponse } from "@/api/types";

const fetchAllNewsletters: ApiRequest<
  RequestResponse<INewsletter[]>,
  void
> = async (options) =>
  request({ ...options, api: ApiPath.Newsletter.base, canFail: true });

const NewsletterController = {
  fetchAllNewsletters,
};

export default NewsletterController;
