import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import EditFormPage from "@/base/components/Form/EditFormPage";
import { validation } from "@/base/components/Form/helpers";
import TextField from "@/base/components/Form/TextField";
import Button from "@/base/components/Global/Button";
import Col from "@/base/components/Layout/Col";
import Row from "@/base/components/Layout/Row";
import ModalArray from "@/base/components/Modal/ModalArray";
import { useUser } from "@/base/components/UserProvider";
import { NavigationPath } from "@/constants/navigation";
import { useAppSelector } from "@/store/hooks";
import { selectUserData } from "@/store/user";

type FormType = {
  firstName: string;
  lastName: string;
};

const validationSchema = yup
  .object({
    firstName: validation.required,
    lastName: validation.required,
  })
  .required();

const EditProfilePage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [currentModal, setCurrentModal] = useState<string | null>(null);
  const userData = useAppSelector(selectUserData);
  const [{ updateProfile }] = useUser();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors, isValid, isSubmitted, isDirty },
  } = useForm<FormType>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      firstName: userData.status === "succeeded" ? userData?.firstName : "",
      lastName: userData.status === "succeeded" ? userData?.lastName : "",
    },
  });

  const onSubmit = async (data: FormType) => {
    setIsSubmitting(true);
    const isSuccess = await updateProfile(data);
    if (isSuccess) {
      setCurrentModal("editSuccessModal");
    } else {
      setCurrentModal("editErrorModal");
    }
    setIsSubmitting(false);
  };

  const hasError = (inputName: keyof FormType) =>
    isSubmitted && Object.keys(errors).includes(inputName);

  return (
    <EditFormPage
      form={{
        headingTitle: t("edit_profile"),
        headingSubTitle: t("edit_profile_subtitle"),
        content: t("edit_profile_content"),
      }}
    >
      <ModalArray
        modals={{
          editSuccessModal: {
            title: t("modal_profile_edited_title"),
            subTitle: t("modal_profile_edited_subtitle"),
            content: t("modal_profile_edited_content"),
          },
          editErrorModal: {
            title: t("modal_profile_edit_error_title"),
            subTitle: t("modal_profile_edit_error_subtitle"),
            content: t("modal_profile_edit_error_content"),
          },
        }}
        currentModal={currentModal}
        onClose={(modal) => {
          setCurrentModal(null);
          if (modal === "editSuccessModal") navigate(NavigationPath.Profile);
        }}
      />
      <Col col={12} noMargin directionColumn alignCenter>
        <Row noMargin style={{ alignItems: "center" }}>
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
            <Col col={12}>
              <TextField
                label={t("field_firstName_label")}
                id="first-name-input"
                autoComplete="name"
                autoFocus
                onEnter={{ trigger, name: "lastName" }}
                placeholder={t("field_firstName_placeholder")}
                helperText={hasError("firstName") && t("field_firstName_error")}
                hasError={hasError("firstName")}
                {...register("firstName")}
              />
            </Col>
            <Col col={12}>
              <TextField
                label={t("field_lastName_label")}
                id="last-name-input"
                autoComplete="name"
                onEnter={{ trigger, name: "submit" }}
                placeholder={t("field_lastName_placeholder")}
                helperText={hasError("lastName") && t("field_lastName_error")}
                hasError={hasError("lastName")}
                {...register("lastName")}
              />
            </Col>
            <Col col={12}>
              <Button
                type="submit"
                isDisabled={!isValid || !isDirty}
                isLoading={isSubmitting}
              >
                {t("action_submit")}
              </Button>
            </Col>
          </form>
        </Row>
      </Col>
    </EditFormPage>
  );
};

export default EditProfilePage;
