import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

import {
  ILocationPageLayout,
  ILocationsBlock,
} from "@/api/interfaces/locationPageLayout";
import LocationIcon from "@/assets/svg/location-with-star-gray.svg?react";
import { Icon, Link } from "@/base/components/Global";
import GoogleMaps from "@/base/components/GoogleMaps";
import { PinProps } from "@/base/components/GoogleMaps/types";
import { Col, Row } from "@/base/components/Layout";
import { useMediaQuery } from "@/base/components/MediaQueryProvider";
import { COPY_SMALL_ICON } from "@/constants/blobIcons";
import { createUseStyles, getTheme } from "@/theme";

import LocationInBuildingMobile from "./LocationInBuildingMobile";

const useStyles = createUseStyles(({ spacing, color }) => ({
  container: {
    // For map, we want to expand larger than parent container
    width: `calc(100% + ${spacing.xxl}px)`,
    marginLeft: -spacing.l,
    marginRight: -spacing.l,
  },
  mapContainer: {
    width: "100%",
    height: "100%",
  },
  businessLocation: {
    backgroundColor: color.gray6,
  },
}));

type SpecificLocationMapProps = ILocationPageLayout & {
  address?: string;
  locationText?: string;
  locations: ILocationsBlock[];
  onCopyUrl: () => void;
};

const SpecificLocationMap: React.FC<SpecificLocationMapProps> = ({
  address,
  locationText,
  locations,
  onCopyUrl,
}) => {
  const classes = useStyles();

  const [searchParams] = useSearchParams();
  const currentLocation = searchParams.get("location");
  const [{ isCurrentSizeMd }] = useMediaQuery();
  const [isLocationModalOpen, setIsLocationModalOpen] = useState(false);
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const { color } = getTheme();

  useEffect(() => {
    mapContainerRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }, []);

  if (!(currentLocation && locations)) return null;

  const locationObject = locations?.find(
    (location) => location.name === currentLocation,
  );

  if (!locationObject) return null;

  const pins = [
    {
      lat: Number(locationObject.latitude),
      lng: Number(locationObject.longitude),
      icon: locationObject.mapPinIconURL,
      brandSystemKey: locationObject.brandSystemKey,
    },
  ] as PinProps[];

  const isMobile =
    isCurrentSizeMd !== undefined && !isCurrentSizeMd && currentLocation;

  if (!isMobile) return null;

  return (
    <>
      <LocationInBuildingMobile
        onClose={() => setIsLocationModalOpen(false)}
        isOpen={isLocationModalOpen}
        floorImageData={locationObject.floorImageData}
        floorInfo={locationObject.floorInfo}
      />
      <Row className={classes.container}>
        <Col className={classes.businessLocation} noMargin>
          <Row noMargin>
            <Col col={12}>
              <Link
                flex
                action={() => {
                  void navigator.clipboard
                    .writeText(window.location.href)
                    .then(onCopyUrl);
                }}
                suffixIcon={
                  <Icon icon={COPY_SMALL_ICON} stroke={color.neutralGray} />
                }
                small
                gray3
              >
                {address}
              </Link>
            </Col>
            <Col col={12} noMargin>
              <div ref={mapContainerRef} className={classes.mapContainer}>
                <GoogleMaps filteredPins={pins} pins={pins} />
              </div>
            </Col>
            {Boolean(
              locationObject.floorImageData || locationObject.floorInfo,
            ) && (
              <Col col={12}>
                <Link
                  flex
                  action={() => setIsLocationModalOpen(true)}
                  prefixIcon={<LocationIcon />}
                  small
                  gray3
                >
                  {locationText}
                </Link>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default SpecificLocationMap;
