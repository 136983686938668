import { isPresent } from "@apl-digital/utils";

export const toTranslationKey = <TKey extends string>(
  errorMap: Record<string, TKey>,
  key: string | undefined,
): TKey => {
  if (!isPresent(key)) {
    return errorMap["default"];
  }

  return errorMap[key];
};
