import { RootState } from "@/store/slices";

const selectCmsState = (state: RootState) => state.cms;

export const selectBusinesses = (state: RootState) => {
  const cmsState = selectCmsState(state);

  return cmsState.businesses;
};

export const selectBusinessNewsletters = (state: RootState) => {
  const cmsState = selectCmsState(state);

  return cmsState.businessNewsletters;
};

export const selectClubPage = (state: RootState) => {
  const cmsState = selectCmsState(state);

  return cmsState.clubPage;
};

export const selectMenu = (state: RootState) => {
  const cmsState = selectCmsState(state);

  return cmsState.menu;
};

export const selectMyGiftCardPage = (state: RootState) => {
  const cmsState = selectCmsState(state);

  return cmsState.myGiftCardPage;
};

export const selectNewslettersPage = (state: RootState) => {
  const cmsState = selectCmsState(state);

  return cmsState.newslettersPage;
};

export const selectPrivateHomePage = (state: RootState) => {
  const cmsState = selectCmsState(state);

  return cmsState.privateHomePage;
};

export const selectProfilePage = (state: RootState) => {
  const cmsState = selectCmsState(state);

  return cmsState.profilePage;
};

export const selectPublicHomePage = (state: RootState) => {
  const cmsState = selectCmsState(state);

  return cmsState.publicHomePage;
};

export const selectAcceptTermsAndConditionsPage = (state: RootState) => {
  const cmsState = selectCmsState(state);

  return cmsState.acceptTermsAndConditionsPage;
};

export const selectCloseAccountPage = (state: RootState) => {
  const cmsState = selectCmsState(state);

  return cmsState.closeAccountPage;
};

export const selectGoodbyePage = (state: RootState) => {
  const cmsState = selectCmsState(state);

  return cmsState.goodbyePage;
};

export const selectLocationsPage = (state: RootState) => {
  const cmsState = selectCmsState(state);

  return cmsState.locationsPage;
};

export const selectTermsAndConditionsPage = (state: RootState) => {
  const cmsState = selectCmsState(state);

  return cmsState.termsAndConditionsPage;
};

export const selectPreviousTermsAndConditionsPage = (state: RootState) => {
  const cmsState = selectCmsState(state);

  return cmsState.previousTermsAndConditionsPage;
};
