import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import { INewPhone } from "@/api/interfaces/userLayouts";
import AddFormPage from "@/base/components/Form/EditFormPage/AddFormPage";
import RepeatFieldForm from "@/base/components/Form/EditFormPage/RepeatFieldForm";
import { validation } from "@/base/components/Form/helpers";
import PhoneField from "@/base/components/Form/PhoneField";
import ModalArray from "@/base/components/Modal/ModalArray";
import { useUser } from "@/base/components/UserProvider";
import { PHONE_ICON } from "@/constants/blobIcons";
import config from "@/constants/config";
import { GOOGLE_ANALYTICS_ATTRIBUTES } from "@/constants/googleAnalytics";
import { NavigationPath } from "@/constants/navigation";
import { useAppSelector } from "@/store/hooks";
import { selectUserData } from "@/store/user";

const validationSchema = yup
  .object({
    newPhone: validation.phone,
    newPhoneVerify: validation.repeat("newPhone"),
  })
  .required();

const AddPhonePage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userData = useAppSelector(selectUserData);
  const [{ addPhone }] = useUser();
  const [currentModal, setCurrentModal] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async (data: Record<string, string>) => {
    const phone: INewPhone = {
      number: data.newPhone,
    };
    setIsSubmitting(true);
    const isSuccess = await addPhone(phone);
    setIsSubmitting(false);
    if (isSuccess) {
      setCurrentModal("successModal");
    } else {
      setCurrentModal("errorModal");
    }
  };

  return (
    <AddFormPage
      didReachMaxRow={Boolean(
        userData.status === "succeeded" &&
          userData.phones.length >= config.appConfig.maxPhoneCount,
      )}
      form={{
        headingIconUrl: PHONE_ICON,
        headingTitle: t("add_phone_header"),
        headingSubTitle: t("add_phone_subtitle"),
        content: t("add_phone_helper_text"),
      }}
    >
      <ModalArray
        modals={{
          successModal: {
            title: t("modal_phone_added_title"),
            subTitle: t("modal_phone_added_subtitle"),
            content: t("modal_phone_added_content"),
            analyticsAttributes: GOOGLE_ANALYTICS_ATTRIBUTES.ADD_PHONE_SUCCESS,
          },
          errorModal: {
            title: t("modal_phone_added_error_title"),
            subTitle: t("modal_phone_added_error_subtitle"),
            content: t("modal_phone_added_error_content"),
            analyticsAttributes: GOOGLE_ANALYTICS_ATTRIBUTES.ADD_PHONE_DECLINE,
          },
        }}
        currentModal={currentModal}
        onClose={() => {
          setCurrentModal(null);
          navigate(NavigationPath.Profile);
        }}
      />
      <RepeatFieldForm
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
        fieldKey="newPhone"
        repeatFieldKey="newPhoneVerify"
        validationSchema={validationSchema}
        fieldComponent={(props) => <PhoneField {...props} />}
        fieldsText={{
          fieldLabel: t("field_enter_phone_label"),
          fieldError: t("field_phone_error"),
          fieldRepeatLabel: t("field_enter_repeat_phone_label"),
          fieldRepeatError: t("phones_dont_match"),
          addRowText: t("field_add_phone_button"),
        }}
        analyticsAttributes={GOOGLE_ANALYTICS_ATTRIBUTES.ADD_PHONE}
      />
    </AddFormPage>
  );
};

export default AddPhonePage;
