import * as React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { Icon } from "@/base/components/Global";
import { formatMoney } from "@/base/utils/formatting";
import { GIFT_CARD_COLORED_ICON } from "@/constants/blobIcons";
import { NavigationPath, PerkGroupName } from "@/constants/navigation";
import { shouldShowBalance } from "@/pages/PerkPage/helpers/perkHelper";
import { useAppSelector } from "@/store/hooks";
import { selectCurrentLanguage } from "@/store/language";
import { selectGroupedPerks } from "@/store/perks";
import { createUseStyles } from "@/theme";

const useStyles = createUseStyles(({ spacing }) => ({
  icon: {
    display: "flex",
    alignItems: "center",
    gap: spacing.s,
  },
}));

type Props = {
  classNames?: string;
};

const PerksTable: React.FC<Props> = ({ classNames }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentLanguage = useAppSelector(selectCurrentLanguage);

  const groupedPerks = useAppSelector(selectGroupedPerks);

  return (
    groupedPerks && (
      <TableContainer className={classNames}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">{t("gift_card_type")}</TableCell>
              <TableCell align="center">{t("gift_card_amount")}</TableCell>
              <TableCell align="center">{t("gift_card_value")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {groupedPerks.map((row) => (
              <TableRow
                key={row.name}
                onClick={() =>
                  navigate({
                    pathname: NavigationPath.PerkGroupPage,
                    search: `?${PerkGroupName}=${row.name}`,
                  })
                }
                style={{ cursor: "pointer" }}
              >
                <TableCell component="th" scope="row" align="center">
                  <span className={classes.icon}>
                    <Icon
                      icon={GIFT_CARD_COLORED_ICON}
                      width={24}
                      height={24}
                    />
                    {row.name.slice(0, 20)}
                  </span>
                </TableCell>
                <TableCell align="center">{row.count}</TableCell>
                <TableCell align="center">
                  {shouldShowBalance(row.type) &&
                    formatMoney(row.balance, currentLanguage)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  );
};

export default PerksTable;
