import React from "react";

import { Icon } from "@/base/components/Global";
import { PinProps } from "@/base/components/GoogleMaps/types";
import { createUseStyles } from "@/theme";

const useStyles = createUseStyles({
  pin: {
    display: "block",
    width: 24,
    height: 24,
    transform: "translate(-50%, -100%)",
  },
});

const Pin: React.FC<PinProps> = ({ icon }) => {
  const classes = useStyles();
  return (
    <div className={classes.pin}>
      <Icon icon={icon} />
    </div>
  );
};

export default Pin;
