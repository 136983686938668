import React, { useEffect, useState } from "react";

import { ICountry, IRegion } from "@/api/interfaces/countriesLayout";
import { RequestResponse } from "@/api/types";
import SelectList, {
  SelectListProps,
  Value,
} from "@/base/components/Form/SelectList";

type CountrySelectProps = SelectListProps & {
  api?: () => RequestResponse<ICountry[]>;
  currentValue?: string;
  setCurrentValue?: (data: Value) => void;
  regions?: IRegion[];
};

const defaultValue = {
  label: "",
  value: "0",
} as Value;

const CountrySelect: React.FC<CountrySelectProps> = ({
  api,
  currentValue,
  setCurrentValue,
  values,
  regions,
  ...props
}) => {
  const [countryValues, setCountryValues] = useState<Value[]>([]);

  useEffect(() => {
    if (api) {
      void api().then(({ isResponseOk, response }) => {
        if (isResponseOk && response?.length > 0) {
          setCountryValues(
            response.map((country: ICountry) => ({
              value: country?.id || "0",
              label: country.name || "",
            })),
          );
        }
      });
    } else if (values) {
      setCountryValues(values);
    } else if (regions) {
      const mappedValues = regions.map((region) => ({
        value: region.id,
        label: region.name,
      }));
      setCountryValues(mappedValues);
    }
  }, []);

  const value = countryValues.filter((i) => i.value === currentValue)?.length
    ? currentValue
    : null;

  return (
    <SelectList
      {...props}
      onChange={(e) => {
        if (countryValues && countryValues?.length > 0 && setCurrentValue) {
          const newValue =
            countryValues?.find(
              (c) => String(c?.value) === String(e.target.value),
            ) ?? defaultValue;
          setCurrentValue(newValue);
        }
      }}
      values={countryValues}
      currentValue={value}
    />
  );
};

export default CountrySelect;
