import * as React from "react";
import { memo } from "react";
const SvgEdit = (props) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: 16,
    height: 16,
    fill: "none",
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "mask",
    {
      id: "99118565-f8dc-4353-997e-bcb0cee60246-a",
      width: 16,
      height: 16,
      x: 0,
      y: 0,
      maskUnits: "userSpaceOnUse",
      style: {
        maskType: "alpha"
      }
    },
    /* @__PURE__ */ React.createElement("path", { fill: "#C4C4C4", d: "M0 0h16v16H0z" })
  ),
  /* @__PURE__ */ React.createElement("g", { stroke: "#F68A1F", mask: "url(#99118565-f8dc-4353-997e-bcb0cee60246-a)" }, /* @__PURE__ */ React.createElement(
    "path",
    {
      strokeLinecap: "round",
      strokeWidth: 1.5,
      d: "m12 2-9.85 9.85a.5.5 0 0 0-.15.36V14h2L14 4z"
    }
  ), /* @__PURE__ */ React.createElement("path", { strokeWidth: 2, d: "m10 4 2 2M2 12l2 2" }))
);
const Memo = memo(SvgEdit);
export default Memo;
