import React, { createContext, useContext, useReducer } from "react";
import { createAction, createReducer } from "@reduxjs/toolkit";

import { AccordionItemType } from "@/base/components/AccordionList/types";

type AccordionContextState = {
  activeItem: AccordionItemType | null;
};

const DEFAULT_STATE: AccordionContextState = {
  activeItem: null,
};

type AccordionContextActions = {
  setActiveItem: (activeItem: AccordionItemType | null) => void;
};

const DEFAULT_ACTIONS: AccordionContextActions = {
  setActiveItem: () => {},
};

const AccordionActions = {
  setActiveItem: createAction<AccordionItemType | null>("SET_ACTIVE_ITEM"),
};

const accordionReducer = createReducer(DEFAULT_STATE, (builder) => {
  builder.addCase(AccordionActions.setActiveItem, (state, { payload }) => {
    state.activeItem = payload;
  });
});

type AccordionContextProps = [AccordionContextState, AccordionContextActions];

const AccordionContext = createContext<AccordionContextProps>([
  DEFAULT_STATE,
  DEFAULT_ACTIONS,
]);

type AccordionListProviderProps = {
  children: React.ReactNode;
};

const useAccordionList = () => useContext(AccordionContext);

const AccordionListProvider: React.FC<AccordionListProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(accordionReducer, { ...DEFAULT_STATE });

  const setActiveItem = (activeItem: AccordionItemType | null): void =>
    dispatch(AccordionActions.setActiveItem(activeItem));

  return (
    <AccordionContext.Provider value={[state, { setActiveItem }]}>
      {children}
    </AccordionContext.Provider>
  );
};

export default AccordionListProvider;
export { useAccordionList };
