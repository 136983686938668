import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { ILocationPageLayout } from "@/api/interfaces/locationPageLayout";
import AccordionItem from "@/base/components/AccordionList/AccordionItem";
import { useAccordionList } from "@/base/components/AccordionList/AccordionListProvider";
import { Icon, Link } from "@/base/components/Global";
import { Col, Row } from "@/base/components/Layout";
import { createUseStyles, getTheme } from "@/theme";

import SpecificLocationMap from "./SpecificLocationMap";

type BusinessListItemProps = ILocationPageLayout & {
  id: string;
  isActive?: boolean;
  onClose: () => void;
  onCopyUrl: () => void;
};

const useStyles = createUseStyles(({ spacing, sizes }) => ({
  container: {
    padding: 0,
    [sizes.md]: {
      padding: [0, spacing.l],
    },
  },
}));

const BusinessListItem: React.FC<BusinessListItemProps> = ({
  locationsBlocks,
  isActive,
  id,
  onClose,
  onCopyUrl,
}) => {
  const classes = useStyles();
  const [, { setActiveItem }] = useAccordionList();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const { color } = getTheme();

  useEffect(() => {
    if (isActive) {
      setActiveItem({ id });
    }
  }, [isActive]);

  if (!locationsBlocks?.length) return null;

  return (
    <Col fullWidth className={classes.container}>
      <AccordionItem
        id={id}
        noBorder
        slim
        icon={
          <Icon
            icon={locationsBlocks.at(0)?.brandLogoURL}
            width={60}
            height={30}
            fill={color.gray2}
          />
        }
        onClose={onClose}
      >
        {locationsBlocks?.map((locationBlock) => {
          const isActiveLink =
            searchParams.get("location") === locationBlock.name;
          return (
            <Row key={locationBlock.name} id={locationBlock.name}>
              <Col directionColumn col={12}>
                <Link
                  action={() => {
                    if (!locationBlock.name) {
                      return;
                    }

                    searchParams.set("location", locationBlock.name);
                    setSearchParams(searchParams);
                  }}
                  brandColor={isActiveLink}
                  black
                  bold
                >
                  <Row>
                    <Col col={12}>{locationBlock.name}</Col>
                  </Row>
                </Link>
              </Col>
              {isActiveLink && (
                <SpecificLocationMap
                  onCopyUrl={onCopyUrl}
                  locations={locationsBlocks}
                  locationText={t("location_in_building")}
                  address={`${locationBlock.name}, ${locationBlock.address}, ${locationBlock.cityName}`}
                />
              )}
            </Row>
          );
        })}
      </AccordionItem>
    </Col>
  );
};

export default BusinessListItem;
