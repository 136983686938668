import React from "react";
import classnames from "classnames";

import {
  TextComponent,
  TextStyleProps,
  TextWithIcon,
} from "@/base/components/Global";
import { MarginProps } from "@/base/components/Layout/constants";
import { createUseStyles } from "@/theme";

const useStyles = createUseStyles<MarginProps>(({ color, font, spacing }) => ({
  h4: {
    margin: 0,
    paddingTop: ({ noMargin, noVerticalMargin, noMarginTop }) =>
      noMargin || noVerticalMargin || noMarginTop ? 0 : spacing.s,
    paddingBottom: ({ noMargin, noVerticalMargin, noMarginBottom }) =>
      noMargin || noVerticalMargin || noMarginBottom ? 0 : spacing.s,
    display: "flex",
    fontSize: font.size.xl,
    fontWeight: font.weight.l,
    lineHeight: font.lineHeight.l,
    color: color.black,
  },
}));

type H4Props = TextStyleProps &
  MarginProps & {
    children?: React.ReactNode | string;
    className?: string | number | boolean | null;
    prefixIcon?: React.ReactNode;
    suffixIcon?: React.ReactNode;
  };

const H4: React.FC<H4Props> = ({
  children,
  className,
  prefixIcon,
  suffixIcon,
  ...props
}) => {
  const classes = useStyles(props);

  return (
    <h4 className={classnames([classes.h4, className])}>
      <TextComponent {...props}>
        <TextWithIcon prefixIcon={prefixIcon} suffixIcon={suffixIcon}>
          {children}
        </TextWithIcon>
      </TextComponent>
    </h4>
  );
};

export default H4;
