import { selectCurrentLanguage } from "@/store/language";
import { AppStartListening } from "@/store/listenerMiddleware";

import { userRefreshing } from "./slice";

export const addUserListeners = (startListening: AppStartListening) => {
  startListening({
    predicate: (_action, currentState, previousState) =>
      selectCurrentLanguage(currentState) !==
      selectCurrentLanguage(previousState),

    effect: (_action, { dispatch }) => {
      dispatch(userRefreshing());
    },
  });
};
