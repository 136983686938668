import React, { useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useClickAway } from "react-use";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import { IPhone } from "@/api/interfaces/userLayouts";
import CheckboxRow from "@/base/components/Form/CheckboxRow";
import PhoneFieldWithController from "@/base/components/Form/Controller/PhoneFieldWithController";
import { Button, P } from "@/base/components/Global";
import { Col } from "@/base/components/Layout";
import Row from "@/base/components/Layout/Row";
import { useUser } from "@/base/components/UserProvider";
import { GOOGLE_ANALYTICS_ATTRIBUTES } from "@/constants/googleAnalytics";
import { createUseStyles } from "@/theme";

const useStyles = createUseStyles(({ spacing }) => ({
  form: {
    display: "flex",
    flexDirection: "column",
  },
  btnRow: {
    paddingTop: spacing.s,
  },
}));

type EditPhoneProps = {
  setModal: (modal: string | null) => void;
} & IPhone;

const PhoneSchema = z
  .object({
    currentPhone: z.string().min(5),
    confirmPhone: z.string(),
  })
  .refine(({ currentPhone, confirmPhone }) =>
    confirmPhone ? currentPhone === confirmPhone : true,
  );

type PhoneData = z.infer<typeof PhoneSchema>;

const EditPhone: React.FC<EditPhoneProps> = ({
  id,
  number,
  isPrimary,
  setModal,
}) => {
  const methods = useForm({
    defaultValues: {
      currentPhone: number,
      confirmPhone: "",
    },
    mode: "onSubmit",
    resolver: zodResolver(PhoneSchema),
  });
  const {
    handleSubmit,
    formState: { errors, isDirty, isSubmitting, isValid },
  } = methods;
  const classes = useStyles();
  const { t } = useTranslation();
  const inputRef = useRef<HTMLDivElement>(null);
  const [isActive, setIsActive] = useState(false);
  const [updateToBePrimary, setUpdateToBePrimary] = useState(false);
  const helperTextCondition =
    !isActive && !isPrimary && !isDirty
      ? t("field_phone_helper_text")
      : undefined;
  const [{ removePhone, updatePhone, setPrimaryPhone }] = useUser();

  const onSubmit = async ({ currentPhone }: PhoneData) => {
    const phone: IPhone = {
      id,
      number: currentPhone,
      isPrimary: updateToBePrimary,
    };
    const isSuccess = await updatePhone(phone);
    const isSuccessPrimary = updateToBePrimary
      ? await setPrimaryPhone(id)
      : true;
    setIsActive(false);
    if (isSuccess && isSuccessPrimary) {
      setModal(updateToBePrimary ? "primarySuccessModal" : "editSuccessModal");
    } else {
      setModal("errorModal");
    }
  };

  useClickAway(inputRef, () => {
    setIsActive(false);
  });

  const deletePhone = async () => {
    const isSuccess = await removePhone(id);
    if (isSuccess) {
      setModal("removeSuccessModal");
    } else {
      setModal("removeErrorModal");
    }
  };

  return (
    <FormProvider {...methods}>
      <div
        onClick={() => setIsActive(true)}
        ref={inputRef}
        className={classes.form}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Col col={12} style={{ paddingBottom: 10 }}>
            <PhoneFieldWithController
              id="phone-input"
              name="currentPhone"
              label={
                isPrimary
                  ? t("field_edit_primary_phone_label")
                  : t("field_edit_secondary_phone_label")
              }
              disabled={isPrimary}
              helperText={
                errors.currentPhone ? t("phone_not_valid") : helperTextCondition
              }
            />
          </Col>
          {isDirty && !isPrimary && (
            <Col col={12} noMarginBottom>
              <PhoneFieldWithController
                id="confirm-phone-input"
                name="confirmPhone"
                label={t("field_edit_repeat_phone_label")}
                disabled={isPrimary}
                helperText={
                  errors.confirmPhone ? t("phones_dont_match") : undefined
                }
              />
            </Col>
          )}
          {!isPrimary && isActive && !isDirty && (
            <Col col={12} directionColumn noVerticalMargin>
              <CheckboxRow
                label={t("checkbox_phone_use_as_primary")}
                slim
                bold={false}
                small
                noBorder
                checked={updateToBePrimary}
                onChange={() => setUpdateToBePrimary(!updateToBePrimary)}
              />
              <P xxSmall gray3 noMargin>
                {t("checkbox_phone_helper_text")}
              </P>
            </Col>
          )}
          {!isPrimary && isActive && (
            <Row className={classes.btnRow}>
              <Col noMarginLeft col={6}>
                <Button
                  transparentBrand
                  action={deletePhone}
                  analyticsAttributes={GOOGLE_ANALYTICS_ATTRIBUTES.DELETE_PHONE}
                  isDisabled={isSubmitting}
                >
                  {t("delete_phone_btn")}
                </Button>
              </Col>
              <Col noMarginRight col={6}>
                <Button
                  type="submit"
                  isLoading={isSubmitting}
                  analyticsAttributes={GOOGLE_ANALYTICS_ATTRIBUTES.PHONE_CHANGE}
                  isDisabled={!isValid || (!isDirty && !updateToBePrimary)}
                >
                  {t("action_save")}
                </Button>
              </Col>
            </Row>
          )}
        </form>
      </div>
    </FormProvider>
  );
};

export default EditPhone;
