import { CountryData, parseCountry } from "react-international-phone";

export const getCountrySearchResult = (
  search: string,
  countries: CountryData[],
): CountryData[] => {
  if (search && search !== "") {
    const searchArray = search
      .replaceAll(/[!"#$%&'()*+,-/:;=?@[\\\]^_`{|}~]/gm, " ")
      .split(" ")
      .filter((v) => v.length);
    if (searchArray.length > 0) {
      let countryCodeMatchArray: CountryData[] = [];
      const startsWithMatchArray: CountryData[] = [];
      const includesMatchArray: CountryData[] = [];
      for (const country of countries) {
        for (const searchValue of searchArray) {
          const { name, dialCode } = parseCountry(country);
          const isCodeMatched = dialCode.slice(1).startsWith(searchValue);
          if (isCodeMatched) {
            countryCodeMatchArray.push(country);
            break;
          }
          const isTitleStarts = name
            .toLowerCase()
            .startsWith(searchValue.toLowerCase());
          if (isTitleStarts) {
            startsWithMatchArray.push(country);
            break;
          }
          const isTitleMatched = name
            .toLowerCase()
            .includes(searchValue.toLowerCase());
          if (isTitleMatched) {
            includesMatchArray.push(country);
            break;
          }
        }
      }

      countryCodeMatchArray = countryCodeMatchArray
        .sort((a, b) => {
          const { dialCode: aDialCode } = parseCountry(a);
          const { dialCode: bDialCode } = parseCountry(b);
          return aDialCode.length - bDialCode.length;
        })
        .sort((a, b) => {
          const { dialCode: aDialCode } = parseCountry(a);
          const { dialCode: bDialCode } = parseCountry(b);
          return Number(aDialCode.slice(1)) - Number(bDialCode.slice(1));
        });

      const filteredCountryCodes = [
        ...countryCodeMatchArray,
        ...startsWithMatchArray,
        ...includesMatchArray,
      ];

      if (filteredCountryCodes.length > 0) {
        return filteredCountryCodes;
      }
    }
  }

  return countries;
};
