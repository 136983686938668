import React from "react";

import { EditFormProps } from "@/base/components/Form/EditFormPage/types";
import { Button, H5, Link, Loader, P } from "@/base/components/Global";
import { Col, Row } from "@/base/components/Layout";
import { createUseStyles } from "@/theme";

const useStyles = createUseStyles(({ spacing }) => ({
  addRow: {
    padding: [spacing.xl, 0, spacing.xl, spacing.s],
  },
  goBackBtn: {
    paddingTop: spacing.x4l,
  },
}));

const EditFormPage: React.FC<EditFormProps> = ({
  form,
  isLoading,
  children,
}) => {
  const classes = useStyles();

  if (isLoading) return <Loader />;

  return (
    <Row noMargin justifyCenter>
      <Col col={12} colMd={8} directionColumn alignCenter noHorizontalMargin>
        <Row>
          {!!form?.headingTitle && (
            <Col noVerticalMargin col={12}>
              <H5 prefixIcon={form.headingIconUrl} gray1>
                {form.headingTitle}
              </H5>
            </Col>
          )}
          {!!form?.headingSubTitle && (
            <Col noMarginTop col={12}>
              <P noMarginTop small gray3>
                {form.headingSubTitle}
              </P>
            </Col>
          )}
          {!!form?.content && (
            <Col col={12}>
              <P black>{form.content}</P>
            </Col>
          )}

          {children}

          {(form.addRow || form.addRowAction) && (
            <Col col={12} className={classes.addRow}>
              {!form.hasMaxRowsReached || !form.maxRowsReachedText ? (
                <Link brandColor action={form.addRowAction}>
                  {form.addRow}
                </Link>
              ) : (
                <P xSmall gray3>
                  {form.maxRowsReachedText}
                </P>
              )}
            </Col>
          )}

          {Boolean(form.goBackText ?? form.goBackAction) && (
            <Row noMargin>
              <Col col={12} colMd={6} className={classes.goBackBtn}>
                <Button
                  transparentBrand
                  action={form.goBackAction}
                  prefixIcon={form.goBackIcon}
                >
                  {form.goBackText}
                </Button>
              </Col>
            </Row>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default EditFormPage;
