import React, { useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";

import Loader from "@/base/components/Global/Loader";
import { Col } from "@/base/components/Layout";
import { Failure } from "@/base/components/MotionSvg";
import { Success } from "@/base/components/MotionSvg";
import {
  clearSessionStorage,
  getSessionStorage,
  SessionStorageKey,
} from "@/base/utils/storage";
import config from "@/constants/config";
import { NavigationPath } from "@/constants/navigation";
import { createUseStyles } from "@/theme";

const useStyles = createUseStyles({
  container: {
    minHeight: "calc(100vh - 90px)",
  },
});

const AuthLandingPage: React.FC = () => {
  const { error, isAuthenticated, isLoading, user, signinSilent } = useAuth();
  const navigate = useNavigate();
  const classes = useStyles();
  const [hasTriedSilentSignin, setHasTriedSilentSignin] = React.useState(false);

  const navigateAway = () => {
    if (error && !isAuthenticated && !user) {
      window.open(config.appConfig.loyaltyHubUrl, "_self");
    }

    const authPreviousPage = getSessionStorage(
      SessionStorageKey.AUTH_PREV_PAGE,
      NavigationPath.Home,
    );

    clearSessionStorage(SessionStorageKey.AUTH_PREV_PAGE);

    navigate(authPreviousPage, { replace: true });
  };

  useEffect(() => {
    if (isLoading) {
      return;
    }

    const timeout = setTimeout(() => navigateAway(), 3000);

    return () => clearTimeout(timeout);
  }, [isLoading]);

  useEffect(() => {
    if (error) {
      console.error("Authentication failed:", error);
      if (!hasTriedSilentSignin) {
        void signinSilent().then(() => {
          setHasTriedSilentSignin(true);
        });
      }
    }
  }, [error, hasTriedSilentSignin]);

  if (!isLoading && error) {
    return (
      <Col alignCenter className={classes.container}>
        <Failure />
      </Col>
    );
  }

  if (!isLoading && isAuthenticated) {
    return (
      <Col alignCenter className={classes.container}>
        <Success />
      </Col>
    );
  }

  return <Loader />;
};

export default AuthLandingPage;
