import React from "react";
import { ParsedCountry } from "react-international-phone";
import classnames from "classnames";

import ArrowSelectIcon from "@/assets/svg/arrow-select.svg?react";
import { createUseStyles } from "@/theme";

import { Flag } from "./Flag";

type StyleProps = {
  isDisabled: boolean;
};

const useStyles = createUseStyles<StyleProps>(({ color, spacing, font }) => ({
  container: {
    display: "flex",
    position: "relative",
    cursor: ({ isDisabled }) => (isDisabled ? "unset" : "pointer"),
    background: "none",
    border: "none",
  },
  countryContainer: {
    display: "flex",
    position: "relative",
    alignItems: "center",
  },
  icon: {
    width: 20,
    height: "unset",
    border: `1px solid ${color.gray4}`,
    marginRight: spacing.xs,
    opacity: ({ isDisabled }) => {
      if (isDisabled) return 0.1;
      return 1;
    },
  },
  arrow: {
    alignSelf: "center",
    marginLeft: spacing.m,
    opacity: ({ isDisabled }) => {
      if (isDisabled) return 0.1;
      return 1;
    },
  },
  arrowUp: {
    transform: "rotate(180deg)",
  },
  countryCode: {
    marginLeft: spacing.m,
    display: "flex",
    fontSize: font.size.s,
    lineHeight: font.lineHeight.m,
    color: ({ isDisabled }) => {
      if (isDisabled) return color.gray5;
      return color.gray1;
    },
  },
}));

type DropdownProps = {
  country: ParsedCountry;
  setOpen: (isOpen: boolean) => void;
  isOpen: boolean;
  isDisabled?: boolean;
  buttonRef: React.RefObject<HTMLButtonElement>;
};

const DropdownButton: React.FC<DropdownProps> = ({
  country,
  setOpen,
  isOpen,
  isDisabled = false,
  buttonRef,
}) => {
  const classes = useStyles({ isDisabled });
  const { iso2 } = country;

  return (
    <button
      className={classes.container}
      onClick={() => !isDisabled && setOpen(!isOpen)}
      type="button"
      ref={buttonRef}
    >
      <div className={classes.countryContainer}>
        {
          <Flag
            iso2={iso2.toLocaleUpperCase() as Uppercase<CountryIso2>}
            className={classes.icon}
          />
        }
        <ArrowSelectIcon
          className={classnames([classes.arrow, isOpen && classes.arrowUp])}
        />
      </div>
    </button>
  );
};

export default DropdownButton;
