import { createSlice } from "@reduxjs/toolkit";

import { IMyApolloClubLayout } from "@/api/interfaces/apolloClubLayout";
import { IBenefit } from "@/api/interfaces/benefitLayouts";
import { ICloseAccountLayout } from "@/api/interfaces/closeAccountLayout";
import { IGenericPageLayout } from "@/api/interfaces/genericPageLayout";
import { IGoodbyeLayout } from "@/api/interfaces/goodbyeLayout";
import {
  IPrivateHomePageLayout,
  IPublicHomePageLayout,
} from "@/api/interfaces/homepageLayout";
import { ILocationPageLayout } from "@/api/interfaces/locationPageLayout";
import IMenuItemLayout from "@/api/interfaces/menuLayout";
import { IMyGiftCardPageLayout } from "@/api/interfaces/myGiftCardPageLayout";
import { IBusinessNewsletter } from "@/api/interfaces/newsLetterLayout";
import { INewslettersPageLayout } from "@/api/interfaces/newslettersPageLayout";
import { IPageSection } from "@/api/interfaces/pageLayout";
import { ApiGetStateMachine, ArrayWrapper } from "@/store/types";
import {
  setStateFailed,
  setStateInitial,
  setStatePending,
} from "@/store/utils";

import {
  fetchAcceptTermsAndConditionsPage,
  fetchBusinesses,
  fetchBusinessNewsletters,
  fetchCloseAccountPage,
  fetchClubPage,
  fetchGoodbyePage,
  fetchLocationsPage,
  fetchMenu,
  fetchMyGiftCardPage,
  fetchNewslettersPage,
  fetchPreviousTermsAndConditionsPage,
  fetchPrivateHomePage,
  fetchProfilePage,
  fetchPublicHomePage,
  fetchTermsAndConditionsPage,
} from "./actions";

type CMSState = {
  publicHomePage: ApiGetStateMachine<IPublicHomePageLayout>;
  privateHomePage: ApiGetStateMachine<IPrivateHomePageLayout>;
  profilePage: ApiGetStateMachine<ArrayWrapper<IPageSection>>;
  menu: ApiGetStateMachine<{
    items: IMenuItemLayout[];
    isUpdated: boolean;
    hasNotifications: boolean;
    giftCardPageNotificationsCount: number;
  }>;
  clubPage: ApiGetStateMachine<IMyApolloClubLayout>;
  businesses: ApiGetStateMachine<ArrayWrapper<IBenefit>>;
  myGiftCardPage: ApiGetStateMachine<IMyGiftCardPageLayout>;
  acceptTermsAndConditionsPage: ApiGetStateMachine<IGenericPageLayout>;
  newslettersPage: ApiGetStateMachine<INewslettersPageLayout>;
  businessNewsletters: ApiGetStateMachine<ArrayWrapper<IBusinessNewsletter>>;
  closeAccountPage: ApiGetStateMachine<ICloseAccountLayout>;
  goodbyePage: ApiGetStateMachine<IGoodbyeLayout>;
  locationsPage: ApiGetStateMachine<ILocationPageLayout>;
  termsAndConditionsPage: ApiGetStateMachine<IGenericPageLayout>;
  previousTermsAndConditionsPage: ApiGetStateMachine<IGenericPageLayout>;
};

const initialState: CMSState = {
  publicHomePage: { status: "idle" },
  privateHomePage: { status: "idle" },
  profilePage: { status: "idle" },
  menu: { status: "idle" },
  clubPage: { status: "idle" },
  businesses: {
    status: "idle",
  },
  myGiftCardPage: { status: "idle" },
  acceptTermsAndConditionsPage: { status: "idle" },
  newslettersPage: { status: "idle" },
  businessNewsletters: { status: "idle" },
  closeAccountPage: { status: "idle" },
  goodbyePage: { status: "idle" },
  locationsPage: { status: "idle" },
  termsAndConditionsPage: { status: "idle" },
  previousTermsAndConditionsPage: { status: "idle" },
};

const CMSSlice = createSlice({
  name: "cms",
  initialState,
  reducers: {
    setHasMenuNotifications(
      state,
      { payload }: { payload: { giftCardPageNotificationsCount: number } },
    ) {
      if (state.menu.status === "succeeded") {
        state.menu.hasNotifications = true;
        state.menu.giftCardPageNotificationsCount =
          payload.giftCardPageNotificationsCount;
      }
    },
    setUpdatedMenuItems(state, { payload }: { payload: IMenuItemLayout[] }) {
      if (state.menu.status === "succeeded") {
        state.menu.items = payload;
        state.menu.isUpdated = true;
      }
    },
    clearCmsState() {
      return initialState;
    },
    cmsRefreshing: (state) => {
      setStateInitial(
        state.publicHomePage,
        (nextState) => (state.publicHomePage = nextState),
      );
      setStateInitial(
        state.privateHomePage,
        (nextState) => (state.privateHomePage = nextState),
      );
      setStateInitial(
        state.profilePage,
        (nextState) => (state.profilePage = nextState),
      );
      setStateInitial(
        state.clubPage,
        (nextState) => (state.clubPage = nextState),
      );
      setStateInitial(
        state.businesses,
        (nextState) => (state.businesses = nextState),
      );
      setStateInitial(
        state.myGiftCardPage,
        (nextState) => (state.myGiftCardPage = nextState),
      );
      setStateInitial(
        state.acceptTermsAndConditionsPage,
        (nextState) => (state.acceptTermsAndConditionsPage = nextState),
      );
      setStateInitial(
        state.newslettersPage,
        (nextState) => (state.newslettersPage = nextState),
      );
      setStateInitial(
        state.closeAccountPage,
        (nextState) => (state.closeAccountPage = nextState),
      );
      setStateInitial(state.menu, (nextState) => (state.menu = nextState));
      setStateInitial(
        state.businessNewsletters,
        (nextState) => (state.businessNewsletters = nextState),
      );
      setStateInitial(
        state.locationsPage,
        (nextState) => (state.locationsPage = nextState),
      );
      setStateInitial(
        state.termsAndConditionsPage,
        (nextState) => (state.termsAndConditionsPage = nextState),
      );
      setStateInitial(
        state.previousTermsAndConditionsPage,
        (nextState) => (state.previousTermsAndConditionsPage = nextState),
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPublicHomePage.pending, (state) => {
        setStatePending(
          state.publicHomePage,
          (nextState) => (state.publicHomePage = nextState),
        );
      })
      .addCase(fetchPublicHomePage.fulfilled, (state, { payload }) => {
        state.publicHomePage = { ...payload, status: "succeeded" };
      })
      .addCase(fetchPublicHomePage.rejected, (state) => {
        setStateFailed(
          state.publicHomePage,
          (nextState) => (state.publicHomePage = nextState),
        );
      });

    builder
      .addCase(fetchPrivateHomePage.pending, (state) => {
        setStatePending(
          state.privateHomePage,
          (nextState) => (state.privateHomePage = nextState),
        );
      })
      .addCase(fetchPrivateHomePage.fulfilled, (state, { payload }) => {
        state.privateHomePage = { ...payload, status: "succeeded" };
      })
      .addCase(fetchPrivateHomePage.rejected, (state) => {
        setStateFailed(
          state.privateHomePage,
          (nextState) => (state.privateHomePage = nextState),
        );
      });

    builder
      .addCase(fetchProfilePage.pending, (state) => {
        setStatePending(
          state.profilePage,
          (nextState) => (state.profilePage = nextState),
        );
      })
      .addCase(fetchProfilePage.fulfilled, (state, { payload }) => {
        state.profilePage = { array: payload, status: "succeeded" };
      })
      .addCase(fetchProfilePage.rejected, (state) => {
        setStateFailed(
          state.profilePage,
          (nextState) => (state.profilePage = nextState),
        );
      });

    builder
      .addCase(fetchMenu.pending, (state) => {
        setStatePending(state.menu, (nextState) => (state.menu = nextState));
      })
      .addCase(fetchMenu.fulfilled, (state, { payload }) => {
        state.menu = {
          status: "succeeded",
          items: payload,
          isUpdated: false,
          hasNotifications: false,
          giftCardPageNotificationsCount: 0,
        };
      })
      .addCase(fetchMenu.rejected, (state) => {
        setStateFailed(state.menu, (nextState) => (state.menu = nextState));
      });

    builder
      .addCase(fetchClubPage.pending, (state) => {
        setStatePending(
          state.clubPage,
          (nextState) => (state.clubPage = nextState),
        );
      })
      .addCase(fetchClubPage.fulfilled, (state, { payload }) => {
        state.clubPage = { ...payload, status: "succeeded" };
      })
      .addCase(fetchClubPage.rejected, (state) => {
        setStateFailed(
          state.clubPage,
          (nextState) => (state.clubPage = nextState),
        );
      });

    builder
      .addCase(fetchBusinesses.pending, (state) => {
        setStatePending(
          state.businesses,
          (nextState) => (state.businesses = nextState),
        );
      })
      .addCase(fetchBusinesses.fulfilled, (state, { payload }) => {
        state.businesses = { status: "succeeded", array: payload };
      })
      .addCase(fetchBusinesses.rejected, (state) => {
        setStateFailed(
          state.businesses,
          (nextState) => (state.businesses = nextState),
        );
      });

    builder
      .addCase(fetchMyGiftCardPage.pending, (state) => {
        setStatePending(
          state.myGiftCardPage,
          (nextState) => (state.myGiftCardPage = nextState),
        );
      })
      .addCase(fetchMyGiftCardPage.fulfilled, (state, { payload }) => {
        state.myGiftCardPage = { ...payload, status: "succeeded" };
      })
      .addCase(fetchMyGiftCardPage.rejected, (state) => {
        setStateFailed(
          state.myGiftCardPage,
          (nextState) => (state.myGiftCardPage = nextState),
        );
      });

    builder
      .addCase(fetchAcceptTermsAndConditionsPage.pending, (state) => {
        setStatePending(
          state.acceptTermsAndConditionsPage,
          (nextState) => (state.acceptTermsAndConditionsPage = nextState),
        );
      })
      .addCase(
        fetchAcceptTermsAndConditionsPage.fulfilled,
        (state, { payload }) => {
          state.acceptTermsAndConditionsPage = {
            ...payload,
            status: "succeeded",
          };
        },
      )
      .addCase(fetchAcceptTermsAndConditionsPage.rejected, (state) => {
        setStateFailed(
          state.acceptTermsAndConditionsPage,
          (nextState) => (state.acceptTermsAndConditionsPage = nextState),
        );
      });

    builder
      .addCase(fetchNewslettersPage.pending, (state) => {
        setStatePending(
          state.newslettersPage,
          (nextState) => (state.newslettersPage = nextState),
        );
      })
      .addCase(fetchNewslettersPage.fulfilled, (state, { payload }) => {
        state.newslettersPage = { ...payload, status: "succeeded" };
      })
      .addCase(fetchNewslettersPage.rejected, (state) => {
        setStateFailed(
          state.newslettersPage,
          (nextState) => (state.newslettersPage = nextState),
        );
      });

    builder
      .addCase(fetchBusinessNewsletters.pending, (state) => {
        setStatePending(
          state.businessNewsletters,
          (nextState) => (state.businessNewsletters = nextState),
        );
      })
      .addCase(fetchBusinessNewsletters.fulfilled, (state, { payload }) => {
        state.businessNewsletters = { status: "succeeded", array: payload };
      })
      .addCase(fetchBusinessNewsletters.rejected, (state) => {
        setStateFailed(
          state.businessNewsletters,
          (nextState) => (state.businessNewsletters = nextState),
        );
      });

    builder
      .addCase(fetchCloseAccountPage.pending, (state) => {
        setStatePending(
          state.closeAccountPage,
          (nextState) => (state.closeAccountPage = nextState),
        );
      })
      .addCase(fetchCloseAccountPage.fulfilled, (state, { payload }) => {
        state.closeAccountPage = { ...payload, status: "succeeded" };
      })
      .addCase(fetchCloseAccountPage.rejected, (state) => {
        setStateFailed(
          state.closeAccountPage,
          (nextState) => (state.closeAccountPage = nextState),
        );
      });

    builder
      .addCase(fetchGoodbyePage.pending, (state) => {
        setStatePending(
          state.goodbyePage,
          (nextState) => (state.goodbyePage = nextState),
        );
      })
      .addCase(fetchGoodbyePage.fulfilled, (state, { payload }) => {
        state.goodbyePage = { ...payload, status: "succeeded" };
      })
      .addCase(fetchGoodbyePage.rejected, (state) => {
        setStateFailed(
          state.goodbyePage,
          (nextState) => (state.goodbyePage = nextState),
        );
      });

    builder
      .addCase(fetchLocationsPage.pending, (state) => {
        setStatePending(
          state.locationsPage,
          (nextState) => (state.locationsPage = nextState),
        );
      })
      .addCase(fetchLocationsPage.fulfilled, (state, { payload }) => {
        state.locationsPage = { ...payload, status: "succeeded" };
      })
      .addCase(fetchLocationsPage.rejected, (state) => {
        setStateFailed(
          state.locationsPage,
          (nextState) => (state.locationsPage = nextState),
        );
      });

    builder
      .addCase(fetchTermsAndConditionsPage.pending, (state) => {
        setStatePending(
          state.termsAndConditionsPage,
          (nextState) => (state.termsAndConditionsPage = nextState),
        );
      })
      .addCase(fetchTermsAndConditionsPage.fulfilled, (state, { payload }) => {
        state.termsAndConditionsPage = { ...payload, status: "succeeded" };
      })
      .addCase(fetchTermsAndConditionsPage.rejected, (state) => {
        setStateFailed(
          state.termsAndConditionsPage,
          (nextState) => (state.termsAndConditionsPage = nextState),
        );
      });

    builder
      .addCase(fetchPreviousTermsAndConditionsPage.pending, (state) => {
        setStatePending(
          state.previousTermsAndConditionsPage,
          (nextState) => (state.previousTermsAndConditionsPage = nextState),
        );
      })
      .addCase(
        fetchPreviousTermsAndConditionsPage.fulfilled,
        (state, { payload }) => {
          state.previousTermsAndConditionsPage = {
            ...payload,
            status: "succeeded",
          };
        },
      )
      .addCase(fetchPreviousTermsAndConditionsPage.rejected, (state) => {
        setStateFailed(
          state.previousTermsAndConditionsPage,
          (nextState) => (state.previousTermsAndConditionsPage = nextState),
        );
      });
  },
  selectors: {
    selectMenuItems: (state) =>
      state.menu.status === "succeeded" ? state.menu.items : null,
    selectHasMenuNotifications: (state) =>
      state.menu.status === "succeeded" ? state.menu.hasNotifications : false,
    selectGiftCardNotificationsCount: (state) =>
      state.menu.status === "succeeded"
        ? state.menu.giftCardPageNotificationsCount
        : 0,
    selectIsMenuItemsUpdated: (state) =>
      state.menu.status === "succeeded" ? state.menu.isUpdated : false,
  },
});

export const {
  selectMenuItems,
  selectHasMenuNotifications,
  selectGiftCardNotificationsCount,
  selectIsMenuItemsUpdated,
} = CMSSlice.selectors;

export const { cmsRefreshing, setHasMenuNotifications, setUpdatedMenuItems } =
  CMSSlice.actions;

export default CMSSlice.reducer;
