import React, { useState } from "react";
import { ChangeHandler } from "react-hook-form";
import { TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { format } from "date-fns";

import { useStyles as useTextFieldStyles } from "@/base/components/Form/TextField";
import { createUseStyles } from "@/theme";

const DATE_FORMAT = "dd/MM/yyyy";

type DateProps = {
  onChange: ChangeHandler;
  label?: string;
  helperText?: string | boolean;
  initialValue?: Date;
  isDisabled?: boolean;
  hasError?: boolean;
  disableFuture?: boolean;
  maxDate?: Date;
};

const useStyles = createUseStyles(({ spacing, color, font }) => ({
  dateContainer: {
    "& .MuiDialogActions-root": {
      padding: [0, 0, spacing.xl],
      "& .MuiButton-root.MuiButton-text": {
        textTransform: "none",
        fontWeight: font.weight.s,
        fontSize: font.size.xs,
        lineHeight: font.lineHeight.xs,
        padding: spacing.s,
        margin: 0,
        color: color.gray3,
        "&:nth-child(2)": {
          color: color.primaryBrand,
        },
      },
    },
    "& .PrivatePickersYear-yearButton": {
      "&.Mui-selected": {
        background: `${color.primaryBrand} !important`,
        fontWeight: font.weight.s,
      },
      "&:focus": {
        background: `${color.primaryBrand} !important`,
        fontWeight: font.weight.s,
      },
      "&:hover": {
        background: `${color.primaryBrand} !important`,
        fontWeight: font.weight.s,
      },
    },
    "& .MuiDialogContent-root > div": {
      flexDirection: "column",
    },
    "& .MuiCalendarPicker-root > div:nth-child(1)": {
      marginTop: spacing.xl,
    },
    "& .MuiPickersToolbar-root": {
      maxWidth: "100% !important",
      width: "100%",
      background: color.gray2,
      color: color.white,
      padding: [spacing.l, spacing.xl],
      "& .MuiTypography-root": {
        color: color.white,
        fontWeight: font.weight.m,
        lineHeight: font.lineHeight.xs,
        fontSize: font.size.xxs,
        paddingTop: spacing.xs,
      },
      "& .MuiPickersToolbar-penIconButton": {
        width: 18,
        height: 18,
        padding: 0,
        margin: [spacing.xs, 0],
        top: 0,
      },
      "& .MuiTypography-h4": {
        fontWeight: font.weight.m,
        fontSize: font.size.xl,
        lineHeight: font.lineHeight.m,
      },
    },
    "& .MuiPickersDay-root": {
      color: color.black,
      fontWeight: font.weight.s,
      fontSize: font.size.s,
      lineHeight: font.lineHeight.m,
      height: 40,
      width: 40,
      margin: 0,
      padding: 0,
      letterSpacing: 0,

      "&.MuiPickersDay-today": {
        border: `1px solid ${color.black}`,
        "&:hover": {
          color: color.white,
        },
        "&:focus": {
          color: color.white,
        },
      },
      "&.Mui-selected": {
        background: `${color.primaryBrand} !important`,
        fontWeight: font.weight.s,
      },
      "&:focus": {
        background: `${color.primaryBrand} !important`,
        fontWeight: font.weight.s,
      },
      "&:hover": {
        background: `${color.primaryBrand} !important`,
        fontWeight: font.weight.s,
      },
    },
  },
}));

// eslint-disable-next-line react/display-name
const DateField = React.forwardRef<DateProps, DateProps>(
  (
    {
      label,
      onChange,
      initialValue,
      isDisabled,
      helperText,
      hasError,
      disableFuture,
      maxDate,
      ...props
    }: DateProps,
    ref,
  ) => {
    const classes = useStyles();
    const textFieldClasses = useTextFieldStyles();
    const [value, setValue] = useState<Date | null>(
      initialValue ? new Date(initialValue) : null,
    );

    const handleChange = (newValue: Date | null) => {
      const event = {
        target: {
          ...props,
          value: newValue ? format(newValue, DATE_FORMAT) : null,
        },
        type: "change",
      };
      setValue(newValue);
      void onChange(event);
    };

    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MobileDatePicker
          label={label}
          format={DATE_FORMAT}
          value={value}
          maxDate={maxDate}
          disableFuture={disableFuture}
          referenceDate={maxDate}
          disabled={isDisabled}
          onChange={handleChange}
          slots={{
            textField: (slotProps) => (
              <div className={textFieldClasses.container}>
                <TextField {...slotProps} />
              </div>
            ),
          }}
          slotProps={{
            toolbar: {
              hidden: true,
            },

            textField: {
              helperText: helperText || undefined,
              InputProps: {
                ref: ref,
                error: hasError,
              },
            },

            dialog: {
              className: classes.dateContainer,
            },
          }}
        />
      </LocalizationProvider>
    );
  },
);

export default DateField;
