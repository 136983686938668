import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { IUserPasswordChangeRequest } from "@/api/interfaces/userLayouts";
import PasswordIcon from "@/assets/svg/password-icon.svg?react";
import { validation } from "@/base/components/Form/helpers";
import PasswordField from "@/base/components/Form/PasswordField";
import { Button, H5, Link } from "@/base/components/Global";
import { Col, Row } from "@/base/components/Layout";
import SimpleModalWrapper from "@/base/components/Modal/SimpleModalWrapper";
import { useUser } from "@/base/components/UserProvider";
import { GOOGLE_ANALYTICS_ATTRIBUTES } from "@/constants/googleAnalytics";
import { NavigationPath } from "@/constants/navigation";
import { createUseStyles } from "@/theme";
import config from "@/constants/config";

const useStyles = createUseStyles(({ spacing }) => ({
  forgotPassword: {
    paddingTop: 4,
    paddingBottom: spacing.xl,
  },
}));

const validationSchema = yup
  .object({
    oldPassword: validation.required,
    newPassword: validation.password,
    newPasswordVerify: validation.repeat("newPassword"),
  })
  .required();

const ChangePasswordPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [{ changePassword }] = useUser();
  const navigate = useNavigate();
  const [currentModal, setCurrentModal] = useState<"success" | "error">();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors, isValid, isSubmitted },
  } = useForm<IUserPasswordChangeRequest>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const hasError = (inputName: keyof IUserPasswordChangeRequest) =>
    isSubmitted && Object.keys(errors).includes(inputName);

  const onSubmit = async (data: IUserPasswordChangeRequest) => {
    setIsSubmitting(true);
    const isSuccess = await changePassword(data);
    if (isSuccess) {
      setCurrentModal("success");
    } else {
      setCurrentModal("error");
    }
    setIsSubmitting(false);
  };

  return (
    <Row noMargin justifyCenter>
      <Col col={12} colMd={8} noMargin directionColumn>
        <Row>
          <Col col={12}>
            <H5>{t("field_new_password")}</H5>
          </Col>
        </Row>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col col={12} noMarginBottom>
              <PasswordField
                label={t("field_current_password_label")}
                id="current-password-input"
                helperText={
                  Boolean(hasError("oldPassword")) && t("field_password_error")
                }
                hasError={hasError("oldPassword")}
                onEnter={{ trigger, name: "newPassword" }}
                placeholder={t("field_current_password_placeholder")}
                {...register("oldPassword")}
              />
            </Col>
            <Col col={12} alignRight className={classes.forgotPassword}>
              <Link
                navigateTo={
                  config.routerConfig.isProfileMode
                    ? NavigationPath.ProfileResetPassword
                    : NavigationPath.ResetPassword
                }
                gray1
                underline
                xSmall
              >
                {t("change_password_page_forgot_password_link")}
              </Link>
            </Col>
            <Col col={12}>
              <PasswordField
                label={t("field_new_password_placeholder")}
                id="new-password-input"
                helperText={
                  Boolean(hasError("newPassword")) &&
                  t("field_password_error_not_valid")
                }
                hasError={hasError("newPassword")}
                onEnter={{ trigger, name: "newPasswordVerify" }}
                placeholder={t("field_new_password_placeholder")}
                {...register("newPassword")}
              />
            </Col>
            <Col col={12}>
              <PasswordField
                label={t("field_verify_new_password_placeholder")}
                id="verify-password-input"
                helperText={
                  Boolean(hasError("newPasswordVerify")) &&
                  t("field_password_match_error")
                }
                hasError={hasError("newPasswordVerify")}
                submitOnEnter
                placeholder={t("field_verify_new_password_placeholder")}
                {...register("newPasswordVerify")}
              />
            </Col>
            <Col col={12}>
              <Button
                type="submit"
                isDisabled={!isValid}
                prefixIcon={<PasswordIcon />}
                isLoading={isSubmitting}
                analyticsAttributes={
                  GOOGLE_ANALYTICS_ATTRIBUTES.PASSWORD_CHANGE
                }
              >
                {t("field_password_update")}
                <SimpleModalWrapper
                  isOpen={currentModal === "success"}
                  onClose={() => {
                    navigate(NavigationPath.Home);
                  }}
                  title={t("password_updated_success_title")}
                  subTitle={t("password_updated_success_subtitle")}
                  content={t("password_updated_success_content")}
                  analyticsAttributes={
                    GOOGLE_ANALYTICS_ATTRIBUTES.PASSWORD_CHANGE_SUCCESS
                  }
                />
                <SimpleModalWrapper
                  isOpen={currentModal === "error"}
                  onClose={() => {
                    setCurrentModal(undefined);
                  }}
                  title={t("password_updated_fail_title")}
                  subTitle={t("password_updated_fail_subtitle")}
                  content={t("password_updated_fail_content")}
                  analyticsAttributes={
                    GOOGLE_ANALYTICS_ATTRIBUTES.PASSWORD_CHANGE_DECLINE
                  }
                />
              </Button>
            </Col>
          </Row>
        </form>
      </Col>
    </Row>
  );
};

export default ChangePasswordPage;
