/*
 *  Avoids running the effect on the mount render
 */

import React, { useEffect, useRef } from "react";

function useDidUpdateEffect(
  fn: React.EffectCallback,
  inputs: React.DependencyList | undefined,
) {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) {
      return fn();
    }
    didMountRef.current = true;
  }, inputs);
}

export default useDidUpdateEffect;
