import React, { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { sub } from "date-fns";

import { MIN_REQUIRED_AGE } from "@/api/constants";
import UserController from "@/api/controller/userController";
import VerificationCodeController from "@/api/controller/verificationCodeController";
import { IRegionPersonalCodeCategory } from "@/api/interfaces/countriesLayout";
import {
  ICompleteUserRegistration,
  PersonalCodeCategory,
} from "@/api/interfaces/userLayouts";
import { VerificationAction } from "@/api/interfaces/verificationCodeLayout";
import { ApiPath } from "@/api/paths";
import CountrySelect from "@/base/components/Form/CountrySelect";
import DateField from "@/base/components/Form/DateField";
import PasswordField from "@/base/components/Form/PasswordField";
import PhoneField from "@/base/components/Form/PhoneField";
import TextField from "@/base/components/Form/TextField";
import FormProvider, {
  FormSize,
  useFormProvider,
} from "@/base/components/FormProvider";
import { Button, H5, Link, P } from "@/base/components/Global";
import { Col, Row } from "@/base/components/Layout";
import SimpleModalWrapper from "@/base/components/Modal/SimpleModalWrapper";
import { useUser } from "@/base/components/UserProvider";
import {
  clearStorage,
  getStorage,
  LocalStorageKey,
} from "@/base/utils/storage";
import { GOOGLE_ANALYTICS_ATTRIBUTES } from "@/constants/googleAnalytics";
import {
  Action,
  ErrorType,
  NavigationPath,
  VerificationCode,
} from "@/constants/navigation";
import NewsletterCheckboxes from "@/pages/UserRegistration/components/NewsletterCheckboxes";
import { verifyVerificationCode } from "@/pages/VerificationCodeErrorPage/helper/VerificationCodeHelper";
import { useAppSelector } from "@/store/hooks";
import {
  selectCurrentRegion,
  selectEnabledRegions,
} from "@/store/region/selectors";
import { createUseStyles } from "@/theme";

import PersonalCodeSelect from "./components/PersonalCodeSelect";

const useStyles = createUseStyles(({ spacing, sizes }) => ({
  leftButton: {
    [sizes.md]: {
      paddingRight: spacing.xxl,
    },
  },
  rightButton: {
    [sizes.md]: {
      paddingLeft: spacing.xxl,
    },
  },
  textSpacing: {
    marginTop: spacing.xs,
    marginBottom: spacing.xs,
  },
}));

const CompleteUserRegistration = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [currentModal, setCurrentModal] = useState<"success" | "error">();
  const [{ logIn }] = useUser();
  const [{ schema }, { getField }] = useFormProvider();
  const firstNameFromStorage = getStorage(
    LocalStorageKey.REGISTRATION_FIRST_NAME,
  );
  const [searchParams] = useSearchParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [personalCodeCategories, setPersonalCodeCategories] = useState<
    IRegionPersonalCodeCategory[]
  >([]);
  const enabledRegions = useAppSelector(selectEnabledRegions);
  const defaultApolloClubCountry = useAppSelector(selectCurrentRegion);
  const [isPersonalCodeSelectionEnabled, setIsPersonalCodeSelectionEnabled] =
    useState(false);

  const resolver =
    schema && Object.keys(schema).length > 0 ? yupResolver(schema) : undefined;

  const {
    trigger,
    register,
    handleSubmit,
    setValue,
    resetField,
    watch,
    formState: { errors, isValid, isSubmitted },
    control,
  } = useForm<ICompleteUserRegistration>({
    mode: "onChange",
    resolver,
    defaultValues: {
      verificationCode: searchParams.get(VerificationCode) ?? undefined,
      preferredClientTerritoryId: defaultApolloClubCountry?.id,
      personalCodeCategory:
        defaultApolloClubCountry?.personalCodeCategories?.at(0)
          ?.personalCodeCategory || PersonalCodeCategory.None,
      isPersonalCodeSelectionEnabled,
    },
  });

  const { fields, append, remove } = useFieldArray({
    name: "keywords",
    control,
  });

  const personalCodeCategory = watch("personalCodeCategory");
  const preferredClientTerritoryId = watch("preferredClientTerritoryId");

  const onSubmit = async (body: ICompleteUserRegistration) => {
    if (isValid) {
      setIsSubmitting(true);
      const { isResponseOk } = await UserController.registerComplete({ body });
      if (isResponseOk && body.verificationCode) {
        await VerificationCodeController.expireCode({
          body: { code: body.verificationCode },
        });
      }
      if (isResponseOk) {
        clearStorage(LocalStorageKey.REGISTRATION_FIRST_NAME);
        setCurrentModal("success");
      } else {
        setCurrentModal("error");
      }
      setIsSubmitting(false);
    }
  };

  const hasError = (inputName: keyof ICompleteUserRegistration) =>
    isSubmitted && Object.keys(errors).includes(inputName);

  useEffect(() => {
    if (enabledRegions && preferredClientTerritoryId) {
      const selectedRegion = enabledRegions.find(
        (region) => region.id === preferredClientTerritoryId,
      );
      setIsPersonalCodeSelectionEnabled(
        Boolean(selectedRegion?.isPersonalCodeSelectionEnabled),
      );
      setPersonalCodeCategories(selectedRegion?.personalCodeCategories ?? []);
    }
  }, [enabledRegions, preferredClientTerritoryId]);

  useEffect(() => {
    if (personalCodeCategories) {
      resetField("personalCodeCategory");
    }
  }, [personalCodeCategories]);

  useEffect(() => {
    setValue("isPersonalCodeSelectionEnabled", isPersonalCodeSelectionEnabled);
  }, [isPersonalCodeSelectionEnabled]);

  useEffect(() => {
    const searchVerificationCode = searchParams.get(VerificationCode);
    void verifyVerificationCode(searchVerificationCode).then(
      ({ isCodeValid, isCodeUsed, status }) => {
        if (isCodeUsed) {
          navigate(NavigationPath.Home);
          return;
        }
        if (!isCodeValid) {
          navigate({
            pathname: NavigationPath.VerificationCodeErrorPage,
            search: `${VerificationCode}=${searchVerificationCode}&${Action}=${VerificationAction.REGISTRATION}&${ErrorType}=${status}`,
          });
        }
      },
    );
  }, [searchParams]);

  const phoneNumberField = getField("phoneNumber");
  const preferredClientTerritoryIdField = getField(
    "preferredClientTerritoryId",
  );
  const personalCodeCategoryField = getField("personalCodeCategory");
  const personalCodeField = getField("personalCode");
  const birthdayField = getField("birthday");
  const passwordField = getField("password");
  const passwordVerifyField = getField("passwordVerify");
  const newsletterField = getField("newsletter");

  // When NONE category is selected or personal code selection is disabled, display DateInput for birthDay.
  const shouldShowPersonalCodeField =
    isPersonalCodeSelectionEnabled && personalCodeCategory
      ? PersonalCodeCategory.None !== personalCodeCategory
      : false;

  const onNewsletterCheck = (isSelected: boolean, id: number): void => {
    if (isSelected) {
      append({
        key: id,
      });
    } else {
      const indexToRemove = fields.findIndex((el) => el.key === id);
      if (indexToRemove !== -1) {
        remove(indexToRemove);
      }
    }
  };

  const resetKeywords = () => {
    setValue("keywords", []);
  };

  useEffect(() => {
    if (shouldShowPersonalCodeField) {
      setValue("birthday", undefined);
    } else {
      setValue("personalCode", undefined);
    }
  }, [personalCodeCategory]);

  const { ref: phoneNumberRef, ...phoneNumberProps } = register("phoneNumber");

  return (
    <Row noMargin>
      <Col col={12} noMargin directionColumn>
        <Row>
          <Col col={12} directionColumn>
            {firstNameFromStorage && (
              <H5>
                {t("welcome_msg_with_name").replace(
                  "%name%",
                  firstNameFromStorage,
                )}
              </H5>
            )}
            {!firstNameFromStorage && <H5>{t("welcome_msg")}</H5>}
            <P>{t("apollo_benefits_register_msg")}</P>
          </Col>
        </Row>
      </Col>
      <form onSubmit={handleSubmit(onSubmit)} style={{ display: "contents" }}>
        <Col col={12} colMd={6} noMargin directionColumn>
          <Row>
            {phoneNumberField?.visible && (
              <FormSize size={phoneNumberField.size}>
                <PhoneField
                  label={t("phone_number")}
                  helperText={hasError("phoneNumber") && t("field_phone_error")}
                  hasError={hasError("phoneNumber")}
                  onEnter={{ trigger, name: "apolloClubCountry" }}
                  innerRef={phoneNumberRef}
                  {...phoneNumberProps}
                  autoFocus={true}
                />
              </FormSize>
            )}
            {/*Feature util to unlock regions by one*/}
            {preferredClientTerritoryIdField?.visible &&
              enabledRegions &&
              enabledRegions.length > 1 && (
                <FormSize size={preferredClientTerritoryIdField.size}>
                  <CountrySelect
                    regions={enabledRegions ?? []}
                    id="preferred-country-select"
                    label={t("apollo_club_country")}
                    helperText={
                      hasError("preferredClientTerritoryId") &&
                      t("field_apolloClubCountry_error")
                    }
                    hasError={hasError("preferredClientTerritoryId")}
                    currentValue={preferredClientTerritoryId}
                    setCurrentValue={({ value }) => {
                      setValue("preferredClientTerritoryId", value, {
                        shouldDirty: true,
                      });
                    }}
                  />
                </FormSize>
              )}
            {personalCodeCategoryField?.visible &&
              isPersonalCodeSelectionEnabled && (
                <FormSize size={personalCodeCategoryField.size}>
                  <PersonalCodeSelect
                    id="personal-code-country-select"
                    label={t("personal_code_country")}
                    helperText={
                      hasError("personalCodeCategory") &&
                      t("field_personalCodeCountry_error")
                    }
                    hasError={hasError("personalCodeCategory")}
                    currentCountryId={preferredClientTerritoryId}
                    currentValue={personalCodeCategory}
                    setCurrentValue={({ value }) => {
                      setValue(
                        "personalCodeCategory",
                        value as PersonalCodeCategory,
                        {
                          shouldDirty: true,
                        },
                      );
                    }}
                  />
                </FormSize>
              )}
            {shouldShowPersonalCodeField && personalCodeField?.visible && (
              <FormSize size={personalCodeField.size}>
                <TextField
                  label={t("personal_code")}
                  id="personal-code-input-bar"
                  placeholder={t("personal_code")}
                  helperText={
                    hasError("personalCode")
                      ? t("field_personalCode_error")
                      : t("personal_code_helper_text")
                  }
                  hasError={hasError("personalCode")}
                  onEnter={{
                    trigger,
                    name: "password",
                  }}
                  {...register("personalCode")}
                  inputMode="numeric"
                />
              </FormSize>
            )}
            {!shouldShowPersonalCodeField && birthdayField?.visible && (
              <FormSize size={birthdayField.size}>
                <DateField
                  disableFuture
                  label={t("birth_date")}
                  maxDate={sub(new Date(), { years: MIN_REQUIRED_AGE })}
                  helperText={
                    hasError("birthday") && t("field_birth_date_error")
                  }
                  hasError={hasError("birthday")}
                  {...register("birthday")}
                />
              </FormSize>
            )}
            {passwordField?.visible && (
              <FormSize size={passwordField.size}>
                <PasswordField
                  label={t("field_password_label")}
                  id="password-input"
                  helperText={
                    Boolean(hasError("password")) &&
                    t("field_password_error_not_valid")
                  }
                  hasError={hasError("password")}
                  placeholder={t("field_password_placeholder")}
                  {...register("password")}
                />
              </FormSize>
            )}
            {passwordVerifyField?.visible && (
              <FormSize size={passwordVerifyField.size}>
                <PasswordField
                  label={t("field_verify_new_password_placeholder")}
                  id="verify-password-input"
                  helperText={
                    Boolean(hasError("passwordVerify")) &&
                    t("field_password_match_error")
                  }
                  hasError={hasError("passwordVerify")}
                  submitOnEnter
                  placeholder={t("field_verify_new_password_placeholder")}
                  {...register("passwordVerify")}
                />
              </FormSize>
            )}
          </Row>
        </Col>
        <Col col={12} colMd={6} noMargin directionColumn>
          <Row>
            <P xSmall bold className={classes.textSpacing} gray3>
              {t("profiling_agree_message")}
            </P>
            <Link
              small
              target="_blank"
              className={classes.textSpacing}
              brandColor
              href={t("profiling_info_checkbox_link_url")}
            >
              {t("profiling_info_checkbox_link_text")}
            </Link>
            {newsletterField?.visible && preferredClientTerritoryId && (
              <FormSize size={newsletterField.size} noMargin directionColumn>
                <Row noMargin>
                  <Col col={12} noMarginTop>
                    <NewsletterCheckboxes
                      countryId={preferredClientTerritoryId}
                      onChange={onNewsletterCheck}
                      reset={resetKeywords}
                    />
                  </Col>
                </Row>
              </FormSize>
            )}
          </Row>
        </Col>
        <Col col={12} noMargin directionColumn>
          <Row>
            <Col aboveMd colMd={6} orderMd={2} />
            <Col col={12} colMd={6} orderMd={4} className={classes.rightButton}>
              <Button
                type="submit"
                id="submit-btn"
                isLoading={isSubmitting}
                analyticsAttributes={
                  GOOGLE_ANALYTICS_ATTRIBUTES.REGISTER_BUTTON_COMPLETE
                }
              >
                {t("register_user")}
                <SimpleModalWrapper
                  isOpen={currentModal === "success"}
                  onClose={() => {
                    navigate(NavigationPath.Home);
                  }}
                  title={t("registration_step2_success_modal_title")}
                  subTitle={t("registration_step2_success_modal_subtitle")}
                  content={t("registration_step2_success_modal_content")}
                />
                <SimpleModalWrapper
                  isOpen={currentModal === "error"}
                  onClose={() => {
                    setCurrentModal(undefined);
                  }}
                  title={t("registration_step2_error_modal_title")}
                  subTitle={t("registration_step2_error_modal_subtitle")}
                  content={t("registration_step2_error_modal_content")}
                />
              </Button>
            </Col>
            <Col belowSm col={12} alignCenter>
              <P xSmall bold>
                {t("account_exists_message")}
              </P>
            </Col>
            <Col aboveMd colMd={6} orderMd={1} directionColumn>
              <P xSmall bold>
                {t("account_exists_message")}
              </P>
            </Col>
            <Col col={12} colMd={6} orderMd={3} className={classes.leftButton}>
              <Button
                transparentBrand
                action={logIn}
                id="sign-in-btn"
                analyticsAttributes={
                  GOOGLE_ANALYTICS_ATTRIBUTES.SIGN_IN_BUTTON_COMPLETE
                }
              >
                {t("sign_in_button")}
              </Button>
            </Col>
          </Row>
        </Col>
      </form>
    </Row>
  );
};

const CompleteUserRegistrationPage: React.FC = () => {
  return (
    <FormProvider url={ApiPath.Form.completeRegistration}>
      <CompleteUserRegistration />
    </FormProvider>
  );
};
export default CompleteUserRegistrationPage;
