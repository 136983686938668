import React from "react";

import IMenuItemLayout from "@/api/interfaces/menuLayout";
import AccordionItem from "@/base/components/AccordionList/AccordionItem";
import MenuItem from "@/base/components/BurgerMenu/components/MenuItem";
import getBurgerMenuItemNavigationPath from "@/base/utils/navigationHelper";

type SimpleMenuProps = {
  item: IMenuItemLayout;
  closeMenu?: () => void;
  isDisabled: boolean;
};

const SimpleMenu: React.FC<SimpleMenuProps> = ({
  item,
  closeMenu,
  isDisabled,
}) => {
  return (
    <AccordionItem
      id={item.title}
      title={item.title}
      iconURL={item.iconURL}
      isDisabled={isDisabled}
    >
      {item.subItems.map((subItem) => (
        <MenuItem
          navigateTo={getBurgerMenuItemNavigationPath(
            subItem.action.path,
            subItem.action.params,
          )}
          key={subItem.title}
          closeMenu={closeMenu}
          isDisabled={isDisabled}
        >
          {subItem.title}
        </MenuItem>
      ))}
    </AccordionItem>
  );
};

export default SimpleMenu;
