import GiftCardController from "@/api/controller/giftCardController";
import PerkController from "@/api/controller/perkController";
import { GiftCardType, IGiftCard } from "@/api/interfaces/giftcardsLayout";
import { IPerk, PerkType } from "@/api/interfaces/perkLayouts";

const fetchPerkByType = async (
  type: PerkType,
  barcode: string,
  crmCode: string,
): Promise<IGiftCard | IPerk | null> => {
  if (type === PerkType.GIFT_CARD) {
    const { isResponseOk, response } = await GiftCardController.fetchGiftCard({
      barcode,
    });
    if (isResponseOk && response) return response;
  } else {
    const { isResponseOk, response } = await PerkController.fetchPerk({
      perkId: barcode,
      perkCrmCode: crmCode,
    });
    if (isResponseOk && response) return response;
  }
  return null;
};

const shouldShowBalance = (type?: PerkType | GiftCardType): boolean =>
  type === PerkType.GIFT_CARD;

const shouldShowPin = (type?: PerkType | GiftCardType): boolean =>
  type === PerkType.GIFT_CARD;

const shouldShowTransactions = (type?: PerkType | GiftCardType): boolean =>
  type === PerkType.GIFT_CARD;

const getPerkInfoTranslationByType = (
  type?: PerkType | GiftCardType,
): string => {
  switch (type) {
    case PerkType.GIFT_CERTIFICATE:
      return "gift_certificate_info_small";
    case PerkType.PRODUCT_VOUCHER:
      return "product_voucher_info_small";
    case PerkType.TICKET_VOUCHER:
      return "ticket_voucher_info_small";
    default:
      return "gift_card_info_small";
  }
};

const getPerkNumberTranslationByType = (
  type?: PerkType | GiftCardType,
): string => {
  switch (type) {
    case PerkType.GIFT_CERTIFICATE:
      return "gift_certificate_number";
    case PerkType.PRODUCT_VOUCHER:
      return "product_voucher_number";
    case PerkType.TICKET_VOUCHER:
      return "ticket_voucher_number";
    default:
      return "gift_card_number";
  }
};

const getBarcodeField = (perk: IPerk | IGiftCard) =>
  perk.type === PerkType.GIFT_CERTIFICATE
    ? perk.barcodeWithoutChecksum
    : perk.usageBarcode;

export {
  fetchPerkByType,
  getBarcodeField,
  getPerkInfoTranslationByType,
  getPerkNumberTranslationByType,
  shouldShowBalance,
  shouldShowPin,
  shouldShowTransactions,
};
