import { initReactI18next } from "react-i18next";
import { isPresent } from "@apl-digital/utils";
import i18n from "i18next";
import HttpApi from "i18next-http-backend";

import translations from "@/assets/locales/dev/translations.json";
import { isInIframe } from "@/base/utils/iframeUtils";
import { getSessionStorage, SessionStorageKey } from "@/base/utils/storage";
import config from "@/constants/config";
import { getDomainLanguage } from "@/store/language";

const currentLanguage = isPresent(
  config.assetConfig.translations.overrideI18nLanguage,
)
  ? config.assetConfig.translations.overrideI18nLanguage
  : getSessionStorage(
      SessionStorageKey.LANGUAGE_CURRENT,
      getDomainLanguage() ?? undefined,
    );

export const defaultNS = "translations";
export const resources = {
  dev: {
    translations,
  },
} as const;

const initI18n = () => {
  if (isInIframe()) {
    return;
  }

  void i18n
    .use(HttpApi)
    .use(initReactI18next)
    .init({
      backend: {
        loadPath: `${config.assetConfig.translations.url}/{{lng}}/{{ns}}.json`,
        crossDomain: true,
        allowMultiLoading: true,
      },
      ns: Object.keys(resources.dev),
      defaultNS,
      lng: currentLanguage,
      fallbackLng: "en",
      returnEmptyString: process.env.NODE_ENV !== "development",
      debug: config.devFlags.translationsDebug,
      interpolation: {
        escapeValue: false,
      },
      react: {
        useSuspense: true,
      },
      partialBundledLanguages: true,
      resources: process.env.NODE_ENV === "development" ? resources : undefined,
    });
};

initI18n();

export { default } from "i18next";
