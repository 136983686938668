import React from "react";

import { IAddress } from "@/api/interfaces/userLayouts";
import { P } from "@/base/components/Global";
import { Col } from "@/base/components/Layout";

const AddressText: React.FC<IAddress> = ({
  addressLine1,
  addressLine2,
  city,
  postalCode,
  country,
  contactName,
  contactPhone,
  contactEmail,
}) =>
  Boolean(
    contactName ||
      addressLine1 ||
      addressLine2 ||
      postalCode ||
      city ||
      country ||
      contactPhone ||
      contactEmail,
  ) ? (
    <>
      {contactName && (
        <Col col={12} noVerticalMargin>
          <P noVerticalMargin>{contactName}</P>
        </Col>
      )}
      {addressLine1 && (
        <Col col={12} noVerticalMargin>
          <P noVerticalMargin>{addressLine1}</P>
        </Col>
      )}
      {addressLine2 && (
        <Col col={12} noVerticalMargin>
          <P noVerticalMargin>{addressLine2}</P>
        </Col>
      )}

      {(postalCode || city) && (
        <Col col={12} noVerticalMargin>
          <P noVerticalMargin>
            {postalCode ? postalCode + " " : ""}
            {city}
          </P>
        </Col>
      )}
      {country && (
        <Col col={12} noVerticalMargin>
          <P noVerticalMargin>{country}</P>
        </Col>
      )}
      {contactPhone && (
        <Col col={12} noVerticalMargin>
          <P noVerticalMargin>{contactPhone}</P>
        </Col>
      )}
      {contactEmail && (
        <Col col={12} noVerticalMargin>
          <P noVerticalMargin>{contactEmail}</P>
        </Col>
      )}
    </>
  ) : null;

export default AddressText;
