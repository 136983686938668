import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import GiftCardController from "@/api/controller/giftCardController";
import PerkController from "@/api/controller/perkController";
import { GiftCardType, IGiftCard } from "@/api/interfaces/giftcardsLayout";
import { IPerk, PerkType } from "@/api/interfaces/perkLayouts";
import { Loader } from "@/base/components/Global";
import { Row } from "@/base/components/Layout";
import {
  GiftCardID,
  NavigationPath,
  PerkCrmCode,
  PerkID,
} from "@/constants/navigation";
import PerkDetails from "@/pages/PerkPage/components/PerkDetails";
import { getBarcodeField } from "@/pages/PerkPage/helpers/perkHelper";

const PerkDetailsPage = () => {
  const navigate = useNavigate();
  const [perk, setPerk] = useState<IGiftCard | IPerk>();
  const [perkType, setPerkType] = useState<PerkType | GiftCardType>();
  const [searchQuery] = useSearchParams();
  const giftCardID = searchQuery.get(GiftCardID);
  const perkID = searchQuery.get(PerkID);
  const crmCode = searchQuery.get(PerkCrmCode);

  useEffect(() => {
    if (giftCardID) {
      void GiftCardController.fetchGiftCard({ barcode: giftCardID }).then(
        ({ isResponseOk, response }) => {
          if (isResponseOk && response) {
            setPerk(response);
            setPerkType(PerkType.GIFT_CARD);
          }
        },
      );
    } else if (perkID && crmCode) {
      void PerkController.fetchPerk({
        perkId: perkID,
        perkCrmCode: crmCode,
      }).then(({ isResponseOk, response }) => {
        if (isResponseOk && response) {
          setPerk(response);
          setPerkType(response.type);
        }
      });
    } else {
      navigate(NavigationPath.Home);
    }
  }, []);

  if (!perk) {
    return <Loader />;
  }

  const barcode = getBarcodeField(perk);

  return (
    <Row>
      <PerkDetails perk={perk} perkType={perkType} barcode={barcode} />
    </Row>
  );
};

export default PerkDetailsPage;
