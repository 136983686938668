import React from "react";
import ReactMarkdown from "react-markdown";
import classnames from "classnames";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";

import { createUseStyles } from "@/theme";

// NB! Do not use this component as standalone, it's meant to be used in generic
export type TextStyleProps = {
  bold?: boolean;
  thin?: boolean;
  error?: boolean;
  brandColor?: boolean;
  black?: boolean;
  white?: boolean;
  gray1?: boolean;
  gray2?: boolean;
  gray3?: boolean;
  gray4?: boolean;
  gray5?: boolean;
  gray6?: boolean;
  isButton?: boolean;
};

const useStyles = createUseStyles<TextStyleProps>(
  ({ color, font, spacing }) => {
    const getColor = ({
      black,
      error,
      white,
      gray1,
      gray2,
      gray3,
      gray4,
      gray5,
      gray6,
      brandColor,
    }: TextStyleProps) => {
      if (error) return color.error;
      if (brandColor) return color.primaryBrand;
      if (black) return color.black;
      if (white) return color.white;
      if (gray1) return color.gray1;
      if (gray2) return color.gray2;
      if (gray3) return color.gray3;
      if (gray4) return color.gray4;
      if (gray5) return color.gray5;
      if (gray6) return color.gray6;
      return "inherit";
    };

    return {
      textStyles: {
        whiteSpace: "pre-wrap",
        display: ({ isButton }) => {
          if (isButton) return "inherit !important";
          return "contents";
        },
        "& ::marker": {
          fontSize: "16px",
        },
        "& li": {
          whiteSpace: "normal",
        },
        "& ul": {
          display: "flex",
          flexDirection: "column",
          paddingLeft: spacing.l,
          color: (props) => getColor(props),
          "& li": {
            fontSize: "inherit",
            lineHeight: "inherit",
          },
        },
        "&, & p": {
          fontWeight: ({ bold, thin }) => {
            if (bold) return font.weight.m;
            if (thin) return font.weight.s;
            return;
          },
          color: (props) => getColor(props),
        },
      },
    };
  },
);

type TextComponentProps = TextStyleProps & {
  children?: React.ReactNode;
  cmsContent?: string;
  classNames?: string;
};

const TextComponent: React.FC<TextComponentProps> = ({
  children,
  cmsContent,
  classNames,
  ...props
}) => {
  const classes = useStyles(props);

  if (cmsContent) {
    return (
      <span className={classnames([classes.textStyles, classNames])}>
        <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
          {cmsContent}
        </ReactMarkdown>
      </span>
    );
  }

  return (
    <span className={classnames([classes.textStyles, classNames])}>
      {children}
    </span>
  );
};

export default TextComponent;
