import React from "react";

import { StyleProps } from "@/base/components/Global/P";
import { createUseStyles } from "@/theme";

const useStyles = createUseStyles<StyleProps>({
  cmsBlocks: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
});

type CMSBlocksProps = {
  children: React.ReactNode;
};

const CmsBlocks: React.FC<CMSBlocksProps> = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.cmsBlocks}>{children}</div>;
};

export default CmsBlocks;
