export enum HttpStatus {
  OK = 200,
  CREATED = 201,
  UNAUTHORIZED = 401,
  CONFLICT = 409,
  INTERNAL_SERVER_ERROR = 500,
}

export const DEFAULT_TERRITORY_ID = "1";
export const NONE_PERSONAL_CODE_CATEGORY = "NONE";
export const MIN_REQUIRED_AGE = 5;
