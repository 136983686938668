import React from "react";
import usePagination from "@mui/material/usePagination";
import classnames from "classnames";

import { Row } from "@/base/components/Layout";
import PerkScrollButton from "@/pages/PerkPage/components/PerkScrollButton";
import { createUseStyles } from "@/theme";

const useStyles = createUseStyles(({ spacing, font, color }) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "300px",
    overflowX: "hidden",
    flexWrap: "nowrap",
  },
  indexes: {
    display: "flex",
    "& span:not(:last-child)": {
      borderRight: `1px solid ${color.gray4}`,
    },
    overflow: "hidden",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    scrollSnapType: "x mandatory",
    width: "unset",
  },
  index: {
    fontSize: font.size.s,
    fontWeight: font.weight.s,
    lineHeight: font.lineHeight.m,
    color: color.gray3,
    padding: [0, spacing.l],
    cursor: "pointer",
    whiteSpace: "nowrap",
    textAlign: "center",
    scrollSnapAlign: "start",
  },
  activeIndex: {
    color: color.black,
  },
}));

type Props = {
  activeIndex: number;
  buttonCount: number;
  onChange: (index: number) => void;
};

const PerkScrollButtons: React.FC<Props> = ({
  activeIndex,
  buttonCount,
  onChange,
}) => {
  const classes = useStyles();

  const { items } = usePagination({
    count: buttonCount,
    onChange: (_, index) => {
      onChange(index - 1);
    },
    page: activeIndex + 1,
    showFirstButton: false,
    showLastButton: false,
    boundaryCount: 0,
  });

  const previousBtnProps = items.find(({ type }) => type === "previous");
  const pagesProps = items.filter(({ type }) => type === "page");
  const nextBtnProps = items.find(({ type }) => type === "next");

  return (
    <Row noMargin className={classes.container}>
      {previousBtnProps && (
        <PerkScrollButton
          isReversed
          disabled={previousBtnProps.disabled}
          onClick={previousBtnProps.onClick}
        />
      )}

      <Row noMargin className={classes.indexes}>
        {pagesProps.map(({ page, selected, ...item }, index) => (
          <span
            key={index}
            className={classnames([
              classes.index,
              selected && classes.activeIndex,
            ])}
            {...item}
          >
            {page}
          </span>
        ))}
      </Row>

      {nextBtnProps && (
        <PerkScrollButton
          disabled={nextBtnProps.disabled}
          onClick={nextBtnProps.onClick}
        />
      )}
    </Row>
  );
};

export default PerkScrollButtons;
