import { useSearchParams } from "react-router-dom";

import { ILocationsBlock } from "@/api/interfaces/locationPageLayout";

const useFilteredLocations = (locations: ILocationsBlock[] | undefined) => {
  const [searchParams] = useSearchParams();

  const countryParam = searchParams.get("country");
  const businessTypeParam = searchParams.get("type");
  const cityParam = searchParams.get("city");

  const isLocationMatchingFilterParams = (loc: ILocationsBlock) => {
    let isMatch = true;

    if (businessTypeParam) {
      isMatch = loc.businessSystemKey === businessTypeParam;
    }

    if (isMatch && countryParam) {
      isMatch = loc.regionSystemKey === countryParam;
    }

    if (isMatch && cityParam) {
      isMatch = loc.citySystemKey === cityParam;
    }

    return isMatch;
  };

  return locations?.filter(isLocationMatchingFilterParams);
};

export default useFilteredLocations;
