import React, { useState } from "react";
import { InputAdornment } from "@mui/material";

import TextField, { TextFieldProps } from "@/base/components/Form/TextField";
import { Icon } from "@/base/components/Global";
import { HIDE_ICON, SHOW_ICON } from "@/constants/blobIcons";

const PasswordField = React.forwardRef<HTMLInputElement, TextFieldProps>(
  function PasswordField(props, ref) {
    const [shouldShowPassword, setShowPassword] = useState(false);

    const PasswordHideShow = () => {
      return (
        <span onClick={() => setShowPassword(!shouldShowPassword)}>
          <Icon icon={shouldShowPassword ? SHOW_ICON : HIDE_ICON} />
        </span>
      );
    };

    return (
      <TextField
        ref={ref}
        autoComplete="password"
        type={shouldShowPassword ? "text" : "password"}
        endAdornment={
          <InputAdornment position="end">
            <PasswordHideShow />
          </InputAdornment>
        }
        {...props}
      />
    );
  },
);

export default PasswordField;
