import { post, put } from "@/api";
import {
  IRequestNewVerificationCodeRequest,
  IValidateVerificationCodeResponse,
  IVerifyVerificationCode,
  ValidateCodeRequest,
} from "@/api/interfaces/verificationCodeLayout";
import { ApiPath } from "@/api/paths";
import { ApiRequest, BodyWrapper, RequestResponse } from "@/api/types";

const validateCode: ApiRequest<
  RequestResponse<IValidateVerificationCodeResponse>,
  BodyWrapper<ValidateCodeRequest>
> = async (options) =>
  post({
    ...options,
    api: ApiPath.VerificationCode.validate,
    canFail: true,
  });

const expireCode: ApiRequest<
  RequestResponse<void>,
  BodyWrapper<IVerifyVerificationCode>
> = async (options) =>
  put({
    ...options,
    api: ApiPath.VerificationCode.expire,
    canFail: true,
    shouldParseResponse: false,
  });

const requestNewRegistrationCode: ApiRequest<
  RequestResponse<void>,
  BodyWrapper<IRequestNewVerificationCodeRequest>
> = async (options) =>
  post({
    ...options,
    api: ApiPath.VerificationCode.newRegistrationCode,
    canFail: true,
    shouldParseResponse: false,
  });

const VerificationCodeController = {
  validateCode,
  expireCode,
  requestNewRegistrationCode,
};

export default VerificationCodeController;
