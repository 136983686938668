import React from "react";

import GrayAvatarIcon from "@/assets/svg/gray_avatar_circle_l.svg?react";
import { H1, Icon } from "@/base/components/Global";
import { Col, Row } from "@/base/components/Layout";
import { useAppSelector } from "@/store/hooks";
import { selectUserData } from "@/store/user";
import { createUseStyles } from "@/theme";

const useStyles = createUseStyles(({ color, spacing }) => ({
  bottomPadding: {
    paddingBottom: 112,
  },
  links: {
    borderLeft: `1px solid ${color.gray5}`,
    paddingLeft: spacing.xl,
    "&:last-child": {
      paddingBottom: 0,
    },
    "&:first-child": {
      paddingTop: 0,
    },
  },
  alternativePicRow: {
    minWidth: 100,
    minHeight: 100,
    width: 100,
    height: 100,
  },
  alternativePic: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  initials: {
    position: "absolute",
    textTransform: "uppercase",
  },
  avatarContainer: {
    alignItems: "start",
    height: "100%",
  },
}));

const INITIALS_REGEX = new RegExp(String.raw`[\s-]`);

const getInitials = (username?: string): string => {
  if (!username) return "";
  return username
    .split(INITIALS_REGEX)
    .slice(0, 2)
    .map((word) => word[0])
    .join("");
};

const Avatar: React.FC = () => {
  const classes = useStyles();
  const userData = useAppSelector(selectUserData);

  return (
    <Col col={5} className={classes.avatarContainer} noMargin>
      <Row noMargin>
        <Col alignRight noMargin>
          <div className={classes.alternativePic}>
            <H1 white className={classes.initials}>
              {userData.status === "succeeded" &&
                getInitials(userData?.firstName)}
            </H1>
            <Icon icon={<GrayAvatarIcon />} width={110} height={110} />
          </div>
        </Col>
      </Row>
    </Col>
  );
};

export default Avatar;
