import React from "react";

import FooterLink from "@/base/components/Footer/FooterLink";
import { footerLinks } from "@/base/components/Footer/helpers";
import InfoBlock from "@/base/components/Footer/InfoBlock";
import ScrollToTopButton from "@/base/components/Footer/ScrollToTopButton";
import { Icon } from "@/base/components/Global";
import { Col, Row } from "@/base/components/Layout";
import { BOTTOM_NAVBAR_HEIGHT } from "@/base/components/TabBar";
import { MARKUS_FOOTER_LOGO_ICON } from "@/constants/blobIcons";
import { createUseStyles } from "@/theme";

const useStyles = createUseStyles(({ color, spacing }) => ({
  container: {
    background: color.black,
    paddingBottom: spacing.xxl + BOTTOM_NAVBAR_HEIGHT,
    marginTop: "auto",
  },
}));

const Footer: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Row noMargin>
        <Col alignCenter noMargin>
          <ScrollToTopButton />
        </Col>
        {footerLinks.map((item) => (
          <FooterLink
            key={item.name}
            name={item.name}
            path={item.path}
            isExternal={item.isExternal}
          />
        ))}
        <InfoBlock />
        <Col alignCenter>
          <Icon icon={MARKUS_FOOTER_LOGO_ICON} width={130} height={46} />
        </Col>
      </Row>
    </div>
  );
};

export default Footer;
