import React, { useState } from "react";

import { Row } from "@/base/components/Layout";
import ApolloClubCardModal from "@/base/components/Modal/ApolloClubCardModal";
import MyInfoBlockDesktop from "@/pages/ProfilePage/components/MyInfoBlock/MyInfoBlockDesktop";
import MyInfoBlockMobile from "@/pages/ProfilePage/components/MyInfoBlock/MyInfoBlockMobile";
import { createUseStyles } from "@/theme";

export type MyInfoBlockProps = {
  showCard?: boolean;
  title?: string;
  subTitle?: string;
  fullProfile?: boolean;
  minimal?: boolean;
  openCardModal?: () => void;
};

export const useMyInfoBlockStyles = createUseStyles(({ color, spacing }) => ({
  wrapperDesktop: {
    paddingBottom: spacing.l,
    paddingTop: spacing.x4l + spacing.m,
  },
  wrapperMobile: {
    paddingTop: spacing.xxl,
    marginBottom: spacing.xl,
    paddingLeft: spacing.xl,
    paddingRight: spacing.xl,
  },
  linksContainer: {
    gap: spacing.l,
    borderLeft: `1px solid ${color.gray5}`,
  },
  links: {
    paddingLeft: spacing.xxl,
  },
  infoCol: {
    alignItems: "start",
    paddingLeft: spacing.xxl,
    paddingRight: spacing.xxl,
  },
  nameWrapper: {
    gap: spacing.m,
    marginBottom: spacing.l,
  },
  qrImageContainer: {
    transition: "transform 0.15s ease-out",
    cursor: "pointer",
    paddingTop: spacing.xxl,
    "@media (hover: hover)": {
      "&:hover": {
        transform: "scale(1.04)",
      },
    },
  },
}));

const MyInfoBlock: React.FC<MyInfoBlockProps> = ({
  showCard = false,
  title,
  subTitle,
  fullProfile = false,
  minimal = false,
}) => {
  const [shouldShowCardModal, setShouldShowCardModal] = useState(false);
  const openCardModal = () => setShouldShowCardModal(true);

  return (
    <Row noMarginLeft noMarginRight>
      <ApolloClubCardModal
        isOpen={shouldShowCardModal}
        onClose={() => {
          setShouldShowCardModal(false);
        }}
        onNavigate={() => setShouldShowCardModal(false)}
      />
      <Row belowSm noMargin>
        <MyInfoBlockMobile
          title={title}
          subTitle={subTitle}
          showCard={showCard}
          fullProfile={fullProfile}
          minimal={minimal}
          openCardModal={openCardModal}
        />
      </Row>
      <Row aboveMd noMargin>
        <MyInfoBlockDesktop hasEdit={!minimal} openCardModal={openCardModal} />
      </Row>
    </Row>
  );
};

export default MyInfoBlock;
