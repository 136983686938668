import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";
import { useNavigate, useParams } from "react-router-dom";

import { AddressType, IAddress } from "@/api/interfaces/userLayouts";
import EditFormPage from "@/base/components/Form/EditFormPage";
import ModalArray from "@/base/components/Modal/ModalArray";
import { useUser } from "@/base/components/UserProvider";
import { GOOGLE_ANALYTICS_ATTRIBUTES } from "@/constants/googleAnalytics";
import { NavigationPath } from "@/constants/navigation";
import AddressField from "@/pages/Address/AddressField";
import { useAppSelector } from "@/store/hooks";
import { selectAddresses } from "@/store/user";

import { getAddressLabels } from "./helpers";

const AddressesPage: React.FC = () => {
  const { addressID } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const addresses = useAppSelector(selectAddresses);
  const [{ removeAddress, updateAddress }] = useUser();
  const { isLoading } = useAuth();
  const [currentModal, setCurrentModal] = useState<string | null>(null);

  const initialAddress = addresses.find((a) => String(a.id) === addressID);
  useEffect(() => {
    if (!isLoading && (addresses.length === 0 || !initialAddress)) {
      // Not loading & have no data to edit
      navigate(NavigationPath.Profile);
    }
  }, [addresses]);
  const { title, subTitle, iconUrl } = getAddressLabels(
    AddressType.MAILING,
    initialAddress,
  );

  const save = async (newAddress: IAddress) => {
    const isSuccess = await updateAddress(newAddress);
    if (isSuccess) {
      setCurrentModal("editSuccessModal");
    } else {
      setCurrentModal("editErrorModal");
    }
  };

  const remove = async () => {
    if (initialAddress?.id) {
      const isSuccess = await removeAddress(initialAddress.id);

      if (isSuccess) {
        setCurrentModal("removeSuccessModal");
      } else {
        setCurrentModal("removeErrorModal");
      }
    }
  };

  return (
    <EditFormPage
      isLoading={!initialAddress}
      form={{
        headingIconUrl: iconUrl,
        // @ts-expect-error: waits for translations to accept dynamic keys
        headingTitle: t(title),
        // @ts-expect-error: waits for translations to accept dynamic keys
        headingSubTitle: t(subTitle),
        content: t("edit_address_helper_text"),
      }}
      fullWidth
    >
      <ModalArray
        modals={{
          editSuccessModal: {
            title: t("modal_address_edit_success_title"),
            subTitle: t("modal_address_edit_success_subtitle"),
            content: t("modal_address_edit_success_content"),
            analyticsAttributes:
              GOOGLE_ANALYTICS_ATTRIBUTES.ADDRESS_CHANGE_SUCCESS,
          },
          editErrorModal: {
            title: t("modal_address_edit_error_title"),
            subTitle: t("modal_address_edit_error_subtitle"),
            content: t("modal_address_edit_error_content"),
            analyticsAttributes:
              GOOGLE_ANALYTICS_ATTRIBUTES.ADDRESS_CHANGE_DECLINE,
          },
          removeSuccessModal: {
            title: t("modal_address_remove_success_title"),
            subTitle: t("modal_address_remove_success_subtitle"),
            content: t("modal_address_remove_success_content"),
            analyticsAttributes:
              GOOGLE_ANALYTICS_ATTRIBUTES.DELETE_ADDRESS_SUCCESS,
          },
          removeErrorModal: {
            title: t("modal_address_remove_error_title"),
            subTitle: t("modal_address_remove_error_subtitle"),
            content: t("modal_address_remove_error_content"),
            analyticsAttributes:
              GOOGLE_ANALYTICS_ATTRIBUTES.DELETE_ADDRESS_DECLINE,
          },
        }}
        currentModal={currentModal}
        onClose={() => {
          setCurrentModal(null);
          navigate(NavigationPath.Addresses);
        }}
      />
      <AddressField address={initialAddress} save={save} remove={remove} />
    </EditFormPage>
  );
};

export default AddressesPage;
