import React, { useCallback, useEffect } from "react";

import { IPerk } from "@/api/interfaces/perkLayouts";
import { FlippableCard } from "@/base/components/FlippableCard/FlippableCard";
import { useMediaQuery } from "@/base/components/MediaQueryProvider";

import { CardBackSide } from "./CardBackSide";
import { CardFrontSide } from "./CardFrontSide";

type PerkCardProps = {
  perk: IPerk;
  isSelected?: boolean;
};

export const PerkCard: React.FC<PerkCardProps> = ({
  perk,
  isSelected = false,
}) => {
  const [{ isCurrentSizeSm }] = useMediaQuery();
  const [isFlipped, setIsFlipped] = React.useState<boolean>(false);

  const toggleFlip = useCallback(() => {
    if (!isSelected) return;

    setIsFlipped((prev) => !prev);
  }, [isSelected, setIsFlipped]);

  useEffect(() => {
    let handle: NodeJS.Timeout | null = null;
    if (!isSelected) {
      handle = setTimeout(() => {
        setIsFlipped(false);
      }, 500);
    }

    return () => {
      if (handle) {
        clearTimeout(handle);
      }
    };
  }, [isSelected]);

  // The size of the actual image is 1080 px x 1740 px
  const width = isCurrentSizeSm ? `${1080 * 0.25}px` : `${1080 * 0.079}cqi`;
  const height = isCurrentSizeSm ? `${1740 * 0.25}px` : `${1740 * 0.079}cqi`;

  return (
    <FlippableCard
      isFlipped={isFlipped}
      width={width}
      height={height}
      disableHoverEffect={true}
      frontside={(props) => (
        <CardFrontSide {...props} perk={perk} onClick={toggleFlip} />
      )}
      backside={(props) => (
        <CardBackSide {...props} perk={perk} onClick={toggleFlip} />
      )}
    />
  );
};
