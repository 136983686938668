import { MutableRefObject } from "react";
import { fitBounds } from "google-map-react";

import { PinProps } from "@/base/components/GoogleMaps/types";

const DEFAULT_ZOOM = 17;
const DEFAULT_SIZE = {
  width: 200,
  height: 300,
};
const INNER_PADDING = 16;

const getBounds = (pins?: PinProps[]) => {
  const lats = pins?.map((pin) => pin.lat).sort((a, b) => a - b) || [0];
  const lngs = pins?.map((pin) => pin.lng).sort((a, b) => a - b) || [0];

  return {
    ne: {
      lat: lats.at(-1) || 0,
      lng: lngs.at(-1) || 0,
    },
    sw: { lat: lats[0], lng: lngs[0] },
  };
};

const getMapSize = (
  ref?: MutableRefObject<HTMLDivElement | null | undefined>,
) => {
  if (ref && ref?.current) {
    return {
      height: ref.current.clientHeight - INNER_PADDING,
      width: ref.current.clientWidth - INNER_PADDING,
    };
  }

  return DEFAULT_SIZE;
};

const getUniquePins = (pins: PinProps[]): PinProps[] => {
  const newPins = [] as PinProps[];

  for (const pin of pins) {
    const foundExactMatch = newPins.find(
      (p) => p.lat === pin.lat && p.lng === pin.lng,
    );
    if (!foundExactMatch) {
      newPins.push(pin);
    }
  }

  return newPins;
};

const getMapValues = (
  pins?: PinProps[],
  mapDivRef?: MutableRefObject<HTMLDivElement | null | undefined>,
) => {
  if (pins) {
    const uniquePins = getUniquePins(pins);
    if (uniquePins.length > 1) {
      const newBounds = getBounds(uniquePins);
      const mapSize = getMapSize(mapDivRef);
      const { zoom, center } = fitBounds(newBounds, mapSize);
      return { zoom, center };
    }
    if (uniquePins.length === 1) {
      return {
        zoom: 13,
        center: { lat: uniquePins[0].lat, lng: uniquePins[0].lng },
      };
    }
  }

  return null;
};

export { DEFAULT_ZOOM, getBounds, getMapValues };
