import { HttpStatus } from "@/api/constants";

import { Language } from "./interfaces/countriesLayout";

export enum ContentType {
  APPLICATION_JSON = "application/json",
}

export enum RequestMethod {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
}

export enum RequestHeader {
  CONTENT_TYPE = "Content-Type",
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RequestBody = Record<string, any> | FormData;

export type ResponseSucceeded<T = void> = {
  response: T;
  isResponseOk: true;
  responseStatus: HttpStatus;
};

export type ResponseFailed<T = void> = {
  request: Omit<FetchApiOptions, "signal">;
  response: T | undefined;
  isResponseOk: false;
  responseStatus: HttpStatus;
  reason: string;
};

type Response<TSuccess, TError> =
  | ResponseSucceeded<TSuccess>
  | ResponseFailed<TError>;

export type RequestResponse<TSuccess, TError = void> = Promise<
  Response<TSuccess, TError>
>;

export type FetchApiOptions = {
  method: RequestMethod;
  api: string;
  shouldParseResponse?: boolean;
  body?: RequestBody;
  headers?: Record<string, string>;
  signal?: AbortSignal;
  language?: Language | null;
  region?: string | null;
};

export type FetchWithErrorsOptions = FetchApiOptions & {
  canFail?: boolean;
};

export type FetchRequestOptions = Omit<FetchWithErrorsOptions, "method">;

export type FetchRequest<TSuccess, TError = void> = (
  options: FetchRequestOptions,
) => RequestResponse<TSuccess, TError>;

export type ApiRequestOptions = Omit<FetchRequestOptions, "api" | "canFail">;

type ApiRequestWithParameters<TResponse, TParameters> = (
  options: TParameters & ApiRequestOptions,
) => TResponse;

type ApiRequestWithoutParameters<TResponse> = (
  options?: ApiRequestOptions,
) => TResponse;

export type ApiRequest<TResponse, TParameters> = TParameters extends void
  ? ApiRequestWithoutParameters<TResponse>
  : ApiRequestWithParameters<TResponse, TParameters>;

export type BodyWrapper<T> = {
  body: T;
};
