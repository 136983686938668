import { deleteRequest, post, put, request } from "@/api";
import { ILoyaltyProgram } from "@/api/interfaces/loyaltyProgramsLayout";
import { ITransaction } from "@/api/interfaces/transactionLayout";
import { IUserBenefit } from "@/api/interfaces/userBenefitLayout";
import {
  DeleteAddressRequest,
  DeleteEmailRequest,
  DeletePhoneRequest,
  IAcceptTermsRequest,
  IActivatePersonContactRequest,
  IAddress,
  IBeginUserRegistration,
  IClosePersonAccountRequest,
  ICompleteUserRegistration,
  IEmail,
  INewEmail,
  INewPhone,
  IPhone,
  IPrimaryAddress,
  IPrimaryContact,
  IRequestPasswordReset,
  IResetPasswordRequest,
  IUpdateNewsletter,
  IUpdatePersonalData,
  IUserData,
  IUserPasswordChangeRequest,
} from "@/api/interfaces/userLayouts";
import { ApiPath } from "@/api/paths";
import { ApiRequest, BodyWrapper, RequestResponse } from "@/api/types";

const getUserData: ApiRequest<RequestResponse<IUserData>, void> = async (
  options,
) => request({ ...options, api: ApiPath.User.base, canFail: true });

const registerStart: ApiRequest<
  RequestResponse<void>,
  BodyWrapper<IBeginUserRegistration>
> = async (options) =>
  post({
    ...options,
    api: ApiPath.User.beginRegistration,
    canFail: true,
    shouldParseResponse: false,
  });

const registerComplete: ApiRequest<
  RequestResponse<void>,
  BodyWrapper<ICompleteUserRegistration>
> = async (options) =>
  post({
    ...options,
    api: ApiPath.User.completeRegistration,
    canFail: true,
    shouldParseResponse: false,
  });

const changePassword: ApiRequest<
  RequestResponse<void>,
  BodyWrapper<IUserPasswordChangeRequest>
> = async (options) =>
  put({
    ...options,
    api: ApiPath.User.changePassword,
    canFail: true,
    shouldParseResponse: false,
  });

const addEmail: ApiRequest<
  RequestResponse<void>,
  BodyWrapper<INewEmail>
> = async (options) =>
  post({
    ...options,
    api: ApiPath.User.contacts.emails,
    canFail: true,
    shouldParseResponse: false,
  });

const deleteEmail: ApiRequest<
  RequestResponse<void>,
  DeleteEmailRequest
> = async ({ id, ...options }) =>
  deleteRequest({
    ...options,
    api: `${ApiPath.User.contacts.emails}/${id}`,
    canFail: true,
    shouldParseResponse: false,
  });

const updateEmail: ApiRequest<
  RequestResponse<void>,
  BodyWrapper<IEmail>
> = async (options) =>
  put({
    ...options,
    api: ApiPath.User.contacts.emails,
    canFail: true,
    shouldParseResponse: false,
  });

const addAddress: ApiRequest<
  RequestResponse<void>,
  BodyWrapper<IAddress>
> = async (options) =>
  post({
    ...options,
    api: ApiPath.User.addresses,
    canFail: true,
    shouldParseResponse: false,
  });

const deleteAddress: ApiRequest<
  RequestResponse<void>,
  DeleteAddressRequest
> = async ({ id, ...options }) =>
  deleteRequest({
    ...options,
    api: `${ApiPath.User.addresses}/${id}`,
    canFail: true,
    shouldParseResponse: false,
  });

const updateAddress: ApiRequest<
  RequestResponse<void>,
  BodyWrapper<IAddress>
> = async (options) =>
  put({
    ...options,
    api: ApiPath.User.addresses,
    canFail: true,
    shouldParseResponse: false,
  });

const addPhone: ApiRequest<
  RequestResponse<void>,
  BodyWrapper<INewPhone>
> = async (options) =>
  post({
    ...options,
    api: ApiPath.User.contacts.phones,
    canFail: true,
    shouldParseResponse: false,
  });

const deletePhone: ApiRequest<
  RequestResponse<void>,
  DeletePhoneRequest
> = async ({ id, ...options }) =>
  deleteRequest({
    ...options,
    api: `${ApiPath.User.contacts.phones}/${id}`,
    canFail: true,
    shouldParseResponse: false,
  });

const updatePhone: ApiRequest<
  RequestResponse<void>,
  BodyWrapper<IPhone>
> = async (options) =>
  put({
    ...options,
    api: ApiPath.User.contacts.phones,
    canFail: true,
    shouldParseResponse: false,
  });

const updateProfile: ApiRequest<
  RequestResponse<void>,
  BodyWrapper<IUpdatePersonalData>
> = async (options) =>
  put({
    ...options,
    api: ApiPath.User.profile,
    canFail: true,
    shouldParseResponse: false,
  });

const setPrimaryContact: ApiRequest<
  RequestResponse<void>,
  BodyWrapper<IPrimaryContact>
> = async (options) =>
  post({
    ...options,
    api: ApiPath.User.contacts.setPrimaryContact,
    canFail: true,
    shouldParseResponse: false,
  });

const setPrimaryAddress: ApiRequest<
  RequestResponse<void>,
  BodyWrapper<IPrimaryAddress>
> = async (options) =>
  post({
    ...options,
    api: ApiPath.User.setPrimaryAddress,
    canFail: true,
    shouldParseResponse: false,
  });

const fetchLoyaltyPrograms: ApiRequest<
  RequestResponse<ILoyaltyProgram[]>,
  void
> = async (options) =>
  request({ ...options, api: ApiPath.User.loyaltyPrograms, canFail: true });

const fetchBenefits: ApiRequest<RequestResponse<IUserBenefit[]>, void> = async (
  options,
) => request({ ...options, api: ApiPath.User.benefits, canFail: true });

const fetchTransactions: ApiRequest<
  RequestResponse<ITransaction[]>,
  void
> = async (options) =>
  request({
    ...options,
    api: ApiPath.User.transactions,
    canFail: true,
  });

const fetchLatestTransactions: ApiRequest<
  RequestResponse<ITransaction[]>,
  void
> = async (options) =>
  request({
    ...options,
    api: ApiPath.User.transactionsLatest,
    canFail: true,
  });

const updateNewsletters: ApiRequest<
  RequestResponse<void>,
  BodyWrapper<IUpdateNewsletter[]>
> = async (options) =>
  put({
    ...options,
    api: ApiPath.User.newsletters,
    canFail: true,
    shouldParseResponse: false,
  });

const acceptTerms: ApiRequest<
  RequestResponse<void>,
  BodyWrapper<IAcceptTermsRequest>
> = async (options) =>
  put({
    ...options,
    api: ApiPath.User.terms,
    canFail: true,
    shouldParseResponse: false,
  });

const requestPasswordReset: ApiRequest<
  RequestResponse<void>,
  BodyWrapper<IRequestPasswordReset>
> = async (options) =>
  post({
    ...options,
    api: ApiPath.User.requestPasswordReset,
    canFail: true,
    shouldParseResponse: false,
  });

const resetPassword: ApiRequest<
  RequestResponse<void>,
  BodyWrapper<IResetPasswordRequest>
> = async (options) =>
  post({
    ...options,
    api: ApiPath.User.resetPassword,
    canFail: true,
    shouldParseResponse: false,
  });

const activateContact: ApiRequest<
  RequestResponse<void>,
  BodyWrapper<IActivatePersonContactRequest>
> = async (options) =>
  put({
    ...options,
    api: ApiPath.User.contacts.activate,
    canFail: true,
    shouldParseResponse: false,
  });

const closePersonAccount: ApiRequest<
  RequestResponse<void>,
  BodyWrapper<IClosePersonAccountRequest>
> = async (options) =>
  post({
    ...options,
    api: ApiPath.User.closePersonAccount,
    canFail: true,
  });

const UserController = {
  getUserData,
  registerStart,
  registerComplete,
  changePassword,
  addEmail,
  deleteEmail,
  updateEmail,
  addAddress,
  deleteAddress,
  updateAddress,
  addPhone,
  deletePhone,
  updatePhone,
  updateProfile,
  setPrimaryContact,
  setPrimaryAddress,
  fetchLoyaltyPrograms,
  fetchBenefits,
  fetchTransactions,
  fetchLatestTransactions,
  updateNewsletters,
  acceptTerms,
  requestPasswordReset,
  resetPassword,
  activateContact,
  closePersonAccount,
};

export default UserController;
