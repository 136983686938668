import React from "react";
import classnames from "classnames";

import { IApolloIntroductionBlock } from "@/api/interfaces/apolloIntroductionLayout";
import GiftSlogan from "@/pages/HomePage/components/IntroductionBlocks/GiftSlogan";
import { createUseStyles } from "@/theme";

const PICTURE_SPACING = 36;

const useStyles = createUseStyles(({ color, borderRadius }) => ({
  pictureContainer: {
    width: "100%",
    position: "relative",
  },
  picture: {
    borderRadius: borderRadius.s,
    backgroundColor: color.gray6,
    backgroundSize: "cover",
    backgroundPosition: "center",
    maxWidth: "100%",
    height: 250,
    width: `calc(100% - ${PICTURE_SPACING}px)`,
  },
  pictureRight: {
    marginLeft: PICTURE_SPACING,
  },
}));

type PictureWithSloganProps = IApolloIntroductionBlock & {
  isLocatedRight: boolean;
};

const PictureWithSlogan: React.FC<PictureWithSloganProps> = ({
  slogan,
  rule,
  iconURL,
  imageURL,
  isLocatedRight,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.pictureContainer}>
      <div
        className={classnames([
          classes.picture,
          !isLocatedRight && classes.pictureRight,
        ])}
        style={{ backgroundImage: `url("${imageURL}")` }}
      />
      <GiftSlogan
        icon={iconURL}
        slogan={slogan}
        rule={rule}
        isLocatedRight={isLocatedRight}
      />
    </div>
  );
};

export default PictureWithSlogan;
