import React from "react";
import classnames from "classnames";

import { createUseStyles } from "@/theme";

const useStyles = createUseStyles(({ spacing }) => ({
  pageWrapper: {
    display: "flex",
    flexDirection: "column",
    padding: [spacing.xl, spacing.l],
  },
}));

type PageWrapperProps = {
  children?: React.ReactNode;
  className?: string | number | boolean | null;
};

const PageWrapper: React.FC<PageWrapperProps> = ({ children, className }) => {
  const classes = useStyles();

  return (
    <div className={classnames([classes.pageWrapper, className])}>
      {children}
    </div>
  );
};

export default PageWrapper;
