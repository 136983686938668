import { getStorage, LocalStorageKey, setStorage } from "@/base/utils/storage";

const INTERVAL_BETWEEN_ERRORS = 5000; // milliseconds
export const ERROR_MESSAGES = "ERROR_MESSAGES";

const cleanupErrorsTimestamps = (): void => {
  const errorsList = JSON.parse(
    getStorage(LocalStorageKey.ERRORS_TIMESTAMPS) || "[]",
  ) as number[];

  if (errorsList.length > 0) {
    const newErrors = errorsList.filter((errorTimestamp) => {
      const currentTimestamp: number = Date.now();
      return errorTimestamp >= currentTimestamp;
    });

    setStorage(LocalStorageKey.ERRORS_TIMESTAMPS, JSON.stringify(newErrors));
  }
};

const addNewError = (error: string) => {
  cleanupErrorsTimestamps();
  const errors = getStorage(LocalStorageKey.ERRORS);
  const errorsList = errors ? (JSON.parse(errors) as string[]) : [];
  const newErrorList = [...errorsList, error];
  const newValue = JSON.stringify(newErrorList);

  setStorage(LocalStorageKey.ERRORS, newValue);
  window.postMessage(ERROR_MESSAGES, window.location.origin);
};

const addErrorTimestamp = (): void => {
  const currentTimestamp: number = Date.now() + INTERVAL_BETWEEN_ERRORS;
  const timestampErrors = JSON.parse(
    getStorage(LocalStorageKey.ERRORS_TIMESTAMPS) || "[]",
  ) as number[];

  const newErrorsTimeStamps = [...timestampErrors, currentTimestamp];

  setStorage(
    LocalStorageKey.ERRORS_TIMESTAMPS,
    JSON.stringify(newErrorsTimeStamps),
  );
};

const emptyErrors = () => {
  setStorage(LocalStorageKey.ERRORS, JSON.stringify([]));
};

const getErrors = (e: MessageEvent) => {
  if (e.data === ERROR_MESSAGES) {
    const errors = getStorage(LocalStorageKey.ERRORS);

    const errorsList = errors ? (JSON.parse(errors) as string[]) : [];

    if (errorsList.length > 0) {
      errorsList.map((err: string) => {
        console.error(err);
      });
      emptyErrors();
      return errorsList;
    }
  }
  return [];
};

export {
  addErrorTimestamp,
  addNewError,
  cleanupErrorsTimestamps,
  emptyErrors,
  getErrors,
};
