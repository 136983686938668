import React from "react";
import { useNavigate } from "react-router-dom";
import classnames from "classnames";

import {
  TextComponent,
  TextStyleProps,
  TextWithIcon,
} from "@/base/components/Global";
import LoaderTiny from "@/base/components/Global/LoaderTiny";
import { AnalyticsAttributes } from "@/constants/googleAnalytics";
import { createUseStyles } from "@/theme";

type StyleProps = TextStyleProps & {
  xSmall?: boolean;
  small?: boolean;
  underline?: boolean;
  block?: boolean;
  flex?: boolean;
  linkColor?: string;
  isActive?: boolean;
  x3Small?: boolean;
};

const useStyles = createUseStyles<StyleProps>(({ color, font }) => ({
  link: {
    cursor: "pointer",
    margin: 0,
    padding: 0,
    alignItems: "center",
    display: ({ block, flex }) => {
      if (block) return "block";
      if (flex) return "flex";
      return "inline-block";
    },
    fontSize: ({ small, xSmall, x3Small }) => {
      if (x3Small) return font.size.x3s;
      if (xSmall) return font.size.xxs;
      if (small) return font.size.xs;
      return font.size.s;
    },
    fontWeight: font.weight.s,
    lineHeight: ({ small, xSmall }) => {
      if (xSmall) return font.lineHeight.xs;
      if (small) return font.lineHeight.s;
      return font.lineHeight.m;
    },
    color: ({ linkColor, isActive }) =>
      isActive ? color.primaryBrand : linkColor || color.black,
    textDecoration: ({ underline }) => (underline ? "underline" : "none"),
  },
  active: {
    color: color.primaryBrand,
  },
  disabled: {
    cursor: "default",
  },
  loading: {
    display: "flex",
  },
}));

type LinkProps = StyleProps & {
  children?: React.ReactNode;
  href?: string;
  target?: "_blank" | "_parent" | "_self" | "_top";
  className?: string;
  navigateTo?: string;
  action?: (() => void) | (() => Promise<void>) | null;
  prefixIcon?: React.ReactNode;
  suffixIcon?: React.ReactNode;
  mailTo?: string;
  isActive?: boolean;
  isDisabled?: boolean;
  "data-testid"?: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  isLoading?: boolean;
  analyticsAttributes?: AnalyticsAttributes;
};

const Link: React.FC<LinkProps> = ({
  children,
  className,
  href,
  target,
  navigateTo,
  action,
  prefixIcon,
  suffixIcon,
  mailTo,
  isActive = false,
  isDisabled = false,
  onMouseEnter,
  onMouseLeave,
  "data-testid": dataTestId,
  isLoading = false,
  analyticsAttributes,
  ...props
}) => {
  const classes = useStyles({ ...props, isActive });
  const navigate = useNavigate();

  const onClick = async (e: React.MouseEvent) => {
    if (isLoading) {
      e.preventDefault();
      return;
    }
    if (action) await action();
    else if (navigateTo) navigate(navigateTo);
  };

  return (
    <a
      className={classnames(
        classes.link,
        className,
        isDisabled && classes.disabled,
        isLoading && classes.loading,
      )}
      data-testid={dataTestId}
      href={mailTo ? `mailto:${mailTo}` : href}
      onClick={onClick}
      target={target}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      {...analyticsAttributes}
    >
      <TextComponent {...props}>
        <TextWithIcon
          prefixIcon={prefixIcon}
          suffixIcon={isLoading ? <LoaderTiny isSmall={true} /> : suffixIcon}
          isActive={isActive}
        >
          {children}
        </TextWithIcon>
      </TextComponent>
    </a>
  );
};

export default Link;
