import React from "react";

import { H5, P } from "@/base/components/Global";
import { CmsPage } from "@/base/components/Global/cmsPage";
import { Col, Row } from "@/base/components/Layout";
import { APOLLO_STAR_ICON } from "@/constants/blobIcons";
import {
  fetchPreviousTermsAndConditionsPage,
  selectPreviousTermsAndConditionsPage,
} from "@/store/cms";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

const PreviousTermsAndConditionsPage: React.FC = () => {
  const previousTermsAndConditionsPage = useAppSelector(
    selectPreviousTermsAndConditionsPage,
  );
  const dispatch = useAppDispatch();

  return (
    <CmsPage
      pageStateMachine={previousTermsAndConditionsPage}
      fetchPage={() => dispatch(fetchPreviousTermsAndConditionsPage())}
    >
      {({ page }) => (
        <Row>
          <Col col={12} directionColumn>
            <H5 prefixIcon={APOLLO_STAR_ICON} gray2>
              {page.title}
            </H5>
            <P cmsContent={page.description} />
          </Col>
        </Row>
      )}
    </CmsPage>
  );
};

export default PreviousTermsAndConditionsPage;
