import React from "react";

import { SectionType } from "@/api/interfaces/pageLayout";
import PerkBlock from "@/base/blocks/PerkBlock/PerkBlock";
import { CmsBlocks } from "@/base/components/Global";
import { CmsPage } from "@/base/components/Global/cmsPage";
import MyNextGiftCardBlock from "@/pages/GiftCardPage/components/MyNextGiftCardBlock";
import {
  getBlock,
  isLastBlock,
} from "@/pages/HomePage/components/helper/cmsHelper";
import { fetchMyGiftCardPage } from "@/store/cms";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

const GiftCardPage = () => {
  const myGiftCardPage = useAppSelector((state) => state.cms.myGiftCardPage);
  const dispatch = useAppDispatch();

  return (
    <CmsPage
      pageStateMachine={myGiftCardPage}
      fetchPage={() => dispatch(fetchMyGiftCardPage())}
    >
      {({ page }) => (
        <CmsBlocks>
          {page.components.map((c, idx, arr) => {
            const isSmall = idx === 0;
            switch (c.type) {
              case SectionType.GIFT_CARDS:
                return getBlock(
                  <PerkBlock block={c} />,
                  idx,
                  isLastBlock(arr.length, idx),
                  isSmall,
                );
              case SectionType.BKK5:
                return getBlock(
                  <MyNextGiftCardBlock block={c} />,
                  idx,
                  isLastBlock(arr.length, idx),
                  isSmall,
                );
              default:
                return null;
            }
          })}
        </CmsBlocks>
      )}
    </CmsPage>
  );
};

export default GiftCardPage;
